import React, { useState, useEffect, useDidShow } from 'react';
import styles from './index.module.scss';
import * as api from "../../api/index"; // 引入api

import { Modal, message, Image } from 'antd';
import { InfoCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const QC = (props) => {
  // 测试图片数据
  const [showImg, setShowImg] = useState();
  const [goodaData, setGoodsData] = useState({});
  useEffect(()=>{
    setGoodsData({})
    getDoodsDetails()
  },[props.goodsId])

  // 获取商品详情
  const getDoodsDetails = () => {
    let params = {
      item_id: props.goodsId
    }

    if (props.activityId) {
      params.activity_id = props.activityId
    }
    api.fixedPriceApi.getReadnew(params).then(res=>{
      if (res.code == 200) {
        setGoodsData(res.data)
        setShowImg(res.data.goods_img[0])
      } else {
        message.info(res.msg);
      }
    })
  }

  const handleOk = () => {
    props.closeQC()
  };
  const handleCancel = () => {
    props.closeQC()
  };
  // 选择展示图
  const selectImg = (item) => {
    console.log(item,111);
    setShowImg(item)
  };

  return (
    <div>
      <Modal wrapClassName={styles.QC} className={styles.modal} width={960} visible={props.modelShow} footer={null} onOk={handleOk} onCancel={handleCancel}>
        <div className={styles.title}>共一份报告，一个商品</div>
        <div className={styles.detail}>
          <div className={styles.left}>
            {
              showImg ? <img referrer="no-referrer|origin|unsafe-url" className={styles.show_img} src={showImg} /> : null
            }
            <div className={styles.img_box}>
              {(goodaData && goodaData.goods_img && goodaData.goods_img.length !==0) ? goodaData.goods_img.map((item,index) => {
                return (
                  <img referrerPolicy="no-referrer|origin|unsafe-url"  key={index} src={item} onClick={()=>selectImg(item)} />
                )
              }):null}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.goods_box}>
              <div className={styles.top}>
                <div className={styles.tag}>{goodaData.grade_text}</div>
                <div className={styles.name}>{goodaData.goods_name}</div>
              </div>
              <div className={styles.bottom}>{goodaData.sku_desc}</div>
            </div>
            <img className={styles.title_img} src='https://resources.huanhuanhuishou.com/new_image/20210907/20210907-092408.png' />
            <div className={styles.info}>
              {goodaData && goodaData.attr && goodaData.attr.length !== 0 ? goodaData.attr.map((item,index) => {
                return (
                  <div key={index} className={styles.info_box}>
                    <div className={styles.title}>{item.name}</div>
                    <div className={styles.infos}>
                      {item.list.map((item1,index1) => {
                        return (
                          <div key={index1} className={styles.text_box}>
                            {
                              item1.list.map((item2,index2) => {
                                return (
                                  <div key={index2} className={styles.flex_align}>
                                    <InfoCircleFilled style={{color:'#ff3232',display:item2.is_normal == 1?'none':'block'}} />
                                    <CheckCircleFilled style={{color:'#11db90',display:item2.is_normal == 1?'block':'none'}} />
                                    <div className={styles.text}>{item2.name}</div>
                                    <Image
                                      width={60}
                                      src={item2.img}
                                      preview={{
                                        src: item2.img,
                                      }}
                                    />
                                    {/* <a href={item2.img} target="_blank" rel="noreferrer" ><img className={styles.origin_img} src={item2.img}></img></a> */}
                                  </div>
                                )
                              })
                            }
                            
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              }):null}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.left}>
            {/* <div className={styles.number}>库存：<span style={{color:'#FF3232'}}>{goodaData.item_count}</span>台</div> */}
            {
              props.activityId ? (<div className={styles.price}>起拍价：<span style={{color:'#FF3232'}}>￥{goodaData.a_max_price}</span></div>):null
            }
            
          </div>
          {/* <div className={styles.right}>出价</div> */}
        </div>
      </Modal>
    </div>
  )
};

export default QC