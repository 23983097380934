import axios from 'axios';

// const BASE_URL = "https://pjtphp.hhyp58.com/"
const BASE_URL = "https://api2021.youpinpaimai.com/"

let Token = localStorage.getItem('access_token')
// 添加请求拦截器
axios.interceptors.request.use((config) => {
  config.data = {
    ...config.data,
    platform: 'youpinpaimai_pc',
    cookie: new Date()
  }
  console.log(config.data,'111');
  // 在发送请求之前做些什么
  return config;
}, (error) => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use( (response)=> {
  Token = localStorage.getItem('access_token')
  // 对响应数据做点什么
  return response;
}, (error) =>{
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default {
  ajaxs({ url = '', params, methods = 'post', header = '', responseType = 'json' }){
    return new Promise((resolve, reject) => {
      axios({
        url: BASE_URL + url,
        data: { action:url, data:params},
        method: methods || 'post',
        // withCredentials: true,
        headers: {
          'Content-Type':'application/json',
          'Authorization': Token || ''
        },
        
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
