
// import { observable ,computed,autorun,action } from 'mobx'
// 1--------observable
// 将属性转为可观察的，一旦发生变化，则变量所处的位置会立即发生变化

// 2--------computed
// 如果使用了@computed （@computed msg getter()），那么msg的值将会被缓存，如果其他值的变化没有触发msg值的变化，那么msg的getter()值就不会改变，index.jsx组件也不会收到通知(componentWillUpdata)。
// @computed的意义在于它能够由MobX进行更智能的优化

// 3--------autorun
// 定义的 @observable 变量如果发生变化，autorun会自动执行相应的方法。

// 4--------action
// 对函数使用@action修饰符后，无论函数中对@observable变量（应用状态）有多少次修改，都只会在函数执行完成后，触发一次对应的监听者(UI更新、网络请求等)。从而更大程度地提升应用的性能，避免多余的UI渲染和网络请求。

// 5--------observer
// 可以用来将 React 组件转变成响应式组件

// *************************在类组件中使用*************************************
// import { inject, observer } from 'mobx-react';
// @inject('AppStore')
// @observer
// class Demo1 extends Component {
//     render() {
//         return <div>{this.props.AppStore.xxx}</div>
//     }
// }

// *************************在Hooks组件中使用*************************************
// const Index = (props) =>{
// console.log('1111111111',props.AppStore,props.CustomAudioStore,);
// }
// export default inject('AppStore','CustomAudioStore')(observer(Index));

import HomeStore from './HomeStore';
class Stores {
  constructor() {
    this.HomeStore = new HomeStore();
  }
}

export default new Stores();