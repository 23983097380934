import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Statistic } from 'antd';
const { Countdown } = Statistic;

const Picker = (props) => {
  // 商品数据
  const goodsList = [
    {
      weiguan:0,
      chujia:0,
      img:'https://image.huanhuanhuishou.com/acb20ea920471084a5818337ae43861c1874175591.jpg',
      time:Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30,
      text:'精选专场',
      onlookers: 1220,
      bid: 129
    },
    {
      weiguan:0,
      chujia:0,
      img:'https://resources.huanhuanhuishou.com/tmp/245CCB5A-93C1-FA49-2237-AEA59671C350.png',
      time:Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30,
      text:'BS机专场',
      onlookers: 2843,
      bid: 241
    },
    {
      weiguan:0,
      chujia:0,
      img:'https://image.huanhuanhuishou.com/fe5d65332367addf3c9c3ecd9a71a215.png',
      time:Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30,
      text:'爱峰派专场',
      onlookers: 443,
      bid: 71
    },
    {
      weiguan:0,
      chujia:0,
      img:'https://image.huanhuanhuishou.com/a012caa0c354bf87e5127c4f09c04158.png',
      time:Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30,
      text:'特卖专场',
      onlookers: 873,
      bid: 116
    },
  ];
  // 倒计时结束
  const onFinish = () => {
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '14px',
    fontFamily: 'PingFang SC',
    fontWeight: '400',
    color: '#fff'
  };
  const joinUnified = () => {
    props.joinUnified()
  }
  return (
    <div className={styles.picker}>
      <div className={styles.title}>统货专场</div>
      <div className={styles.tag}>配件、数码、电子产品，每天12:00~18:00准时开场</div>
      <div className={styles.content} onClick={joinUnified}>
        {goodsList.map((item,index)=>{
          return (
            <div className={styles.goods_box} key={index}>
              <img className={styles.tag_img} src='https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_tpTag.png' />
              <div className={styles.degree}>{item.onlookers}次围观 <div className={styles.br}></div> {item.bid}次出价</div>
              <img className={styles.goods_img} src={item.img} />
              <div className={styles.name}>{item.text}</div>
              {/* <div className={styles.time}>
                <div style={{marginRight:'4px'}}>剩余</div>
                <Countdown value={item.time} onFinish={onFinish} valueStyle={countdownStyle} />
              </div> */}
            </div>
          )
        })}
      </div>
    </div>
  )
};

export default Picker