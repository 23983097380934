import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import * as api from "../../../../../api/index"; // 引入api

import { Link } from 'react-router-dom';
import { Select, Button, Space, Table, Modal, Input, message } from 'antd';
const { Option } = Select;

const Content = (props) => {
  const [classValue,setClassValue] = useState();
  const [brandValue,setBrandValue] = useState();
  const [goodsValue,setGoodsValue] = useState();
  const [gradeValue,setGradeValue] = useState();
  const [brandList,setBrandList] = useState([]);
  const [goodsList,setGoodsList] = useState([]);
  const [cancelPriceId,setCancelPriceId] = useState();
  const [countermandShow, setCountermandShow] = useState(false); // 取消议价确认框
  const [myGoPrice,setMyGoPrice] = useState();
  const [buyerNumber,setBuyerNumber] = useState();
  const [singleRecord,setSingleRecord] = useState([]);
  const [goPrice,setGoPrice] = useState();
  const [resets,setResets] = useState(false);
  const [selectGoodsId,setSelectGoodsId] = useState()
  const [alterShow, setAlterShow] = useState(false); // 改价确认框
  const [isRecord,setIsRecord] = useState(false); // 改价确认框或者记录框
  const input = React.createRef()
  // 打开取消议价确认框
  const openCountermand = (id) => {
    setCancelPriceId(id)
    setCountermandShow(true)
  };
  // 确认取消议价
  const countermandOk = () => {
    api.negotiatedPriceApi.cancelPrice({id:cancelPriceId}).then(res=>{
      if (res.code === 200) {
        message.success('取消成功');
        // search()
        props.search(selectGoodsId,gradeValue,false)
        setCountermandShow(false)
      }
    })
  };
  // 关闭取消议价确认框
  const countermandCancel = () => {
    setCountermandShow(false)
  };
  // 打开改价确认框
  const openAlter = (item) => {
    setIsRecord(false)
    setMyGoPrice(item.price)
    setCancelPriceId(item.id)
    api.negotiatedPriceApi.getPersonalRecord({id:item.goods_talk_list_id}).then(res=>{
      if (res.code === 200) {
        setBuyerNumber(res.data.count)
        res.data.list.sort((a,b)=>{return b.price - a.price})
        res.data.list.filter((item,index)=>{
          item.ranking = index + 1
        })
        setSingleRecord(res.data.list)
      }
    })
    setAlterShow(true)
  };
  // 出价输入框
  const goPriceChange = (e) => {
    setTimeout(() => {
      console.log(e.target.defaultValue,'e.target.defaultValue');
      setGoPrice(e.target.defaultValue)
    }, 1);
  };
  // 确认提交改价
  const alterOk = () => {
    let reg = /^[0-9]*[1-9][0-9]*$/
    if (reg.test(goPrice) != true) {
      message.error('请输入整数价格且价格不能为0');
      return
    }
    api.negotiatedPriceApi.savePrice({price:goPrice,id:cancelPriceId}).then(res=>{
      if (res.code === 200) {
        // search()
        console.log(selectGoodsId,gradeValue,'65666');
        if (resets) {
          props.search('','',false)
        } else {
          props.search(selectGoodsId,gradeValue,false)
        }
        message.success('出价成功');
        alterCancel()
      }
    })
    setAlterShow(false)
    input.current.state.value = ''
  };
  // 关闭改价确认框
  const alterCancel = () => {
    setAlterShow(false)
    input.current.state.value = ''
  };
  // 打开记录框
  const openRecord = (item) => {
    api.negotiatedPriceApi.getRecord({id: item.id, limit:999999999, page:1}).then(res=>{
      if (res.code === 200) {
        res.data.sort((a,b)=>{return b.price - a.price})
        res.data.filter((item,index)=>{
          item.ranking = index + 1
        })
        setSingleRecord(res.data)
      }
    })
    setIsRecord(true)
    setAlterShow(true)
  };
  // 表格数据
  const dataSource = props.list
  const columns = [
    {
      title: '商品名称',
      dataIndex: 'goods_name',
      key: 'goods_name',
    },
    {
      title: '等级',
      dataIndex: 'grade_text',
      key: 'grade_text',
    },
    {
      title: 'SKU',
      dataIndex: 'sku_desc',
      key: 'sku_desc'
    },
    {
      title: '议价金额',
      dataIndex: 'reference_price',
      key: 'reference_price',
      render: (reference_price) => (
        <Space size="middle">
          <span>￥{reference_price}</span>
        </Space>
      ),
    },
    {
      title: '我的出价',
      dataIndex: 'price',
      key: 'price',
      render: (price) => (
        <Space size="middle">
          <span>￥{price}</span>
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          {/* <span onClick={()=>openCountermand(id)} style={{color: '#169BD5', cursor: 'pointer'}}>取消</span> */}
          <span onClick={()=>openAlter(item)} style={{color: '#169BD5', cursor: 'pointer', display: props.tabs == '0' ? 'block' : 'none'}}>改价</span>
          <span style={{display: props.tabs == '1' || (item.state === 1 && props.tabs == '1,2') ? 'block' : 'none'}}>议价成功</span>
          <span style={{display: props.tabs == '1,2' && item.state === 2 ? 'block' : 'none'}}>议价失败</span>
          <span onClick={()=>openRecord(item)} style={{color: '#169BD5', cursor: 'pointer', display: props.tabs == '1,2' ? 'block' : 'none'}}>议价记录</span>
        </div>
      ),
    },
  ];
  // 分页配置
  const pageOption = {
    pageSize:5,
    total: props.list.length,
    showTotal: ()=>{
      return '共 '+props.list.length+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    }
  }
  // 品类筛选
  const classChange = (value) => {
    setResets(false)
    setClassValue(value)
    setBrandValue()
    setGoodsValue()
    setGoodsList([])
    console.log('选择的类型' + value);
    props.filterData.search.model.map(item=>{
      if (item.id === value) {
        setBrandList(item.list)
      }
    })
  };
  // 品牌筛选
  const brandChange = (value) => {
    setBrandValue(value)
    setGoodsValue()
    console.log('选择的类型' + value);
    let params = {
      brand_id: value,
      class_id: classValue,
      state: props.tabs
    }
    api.negotiatedPriceApi.getGoodsTypes(params).then(res=>{
      if (res.code === 200) {
        setGoodsList(res.data)
      }
    })
  };
  // 型号筛选
  const goodsChange = (value) => {
    setGoodsValue(value)
    console.log('选择的类型' + value);
  };
  // 等级筛选
  const gradeChange = (value) => {
    setResets(false)
    setGradeValue(value)
    console.log('选择的等级' + value);
  };
  // 查询搜索
  const search = (isReset) => {
    if (!brandValue && brandValue !==0) {
      return message.warning('请先选择品牌后进行筛选')
    }
    let selectGoodsId = ''
    if (goodsValue && goodsValue != 0) {
      selectGoodsId = goodsValue
    } else {
      if (brandValue) {
        let arr = []
        goodsList.forEach(item=>{
          arr.push(item.goods_id)
        })
        console.log(arr,'arr');
        selectGoodsId = arr.join(',')
      }
    }
    setSelectGoodsId(selectGoodsId)
    if (isReset) {
      props.search()
    } else {
      props.search(selectGoodsId,gradeValue)
    }
  };
  // 重置筛选项
  const reset = () => {
    setResets(true)
    setSelectGoodsId()
    setClassValue()
    setBrandValue()
    setGoodsValue()
    goodsChange()
    setGradeValue()
    setBrandList([])
    setGoodsList([])
    search(true)
  };
  // 表格分页跳转
  const pageChange = () => {
    console.log('分页跳转');
  };
  return (
    <div>
      <div className={styles.filter_box}>
        {/* 品类 */}
        <div className={styles.brand_box}>
          <div>品类：</div>
          <Select value={classValue} placeholder='请选择品类' style={{ width: 130 }} onChange={classChange}>
            {
              props.filterData && props.filterData.cate_list ? props.filterData.cate_list.map( item =>{
                if (item.id !== 0) {
                  return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )
                }
              }) : null
            }
          </Select>
        </div>
        {/* 品牌 */}
        <div className={styles.brand_box}>
          <div>品牌：</div>
          <Select value={brandValue} placeholder='请选择品牌' style={{ width: 130 }} onChange={brandChange}>
            {
              brandList && brandList.length !== 0 ? brandList.map(item => {
                return (
                  <Option key={item.brand_id} value={item.brand_id}>{item.brand_name}</Option>
                )
              }) : null
            }
          </Select>
        </div>
        {/* 型号 */}
        <div className={styles.brand_box}>
          <div>型号：</div>
          <Select value={goodsValue} placeholder='请选择型号' style={{ width: 130 }} onChange={goodsChange}>
            {
              goodsList && goodsList.length !== 0 ? goodsList.map(item => {
                return (
                  <Option key={item.goods_id} value={item.goods_id}>{item.goods_name}</Option>
                )
              }) : null
            }
          </Select>
        </div>
        {/* 等级 */}
        <div className={styles.brand_box}>
          <div>等级：</div>
          <Select value={gradeValue} placeholder='请选择等级' style={{ width: 130 }} onChange={gradeChange}>
            {
              props.gradeData ? props.gradeData.map(item=>{
                return (
                  <Option key={item.key} value={item.key}>{item.value}</Option>
                )
              }) : null
            }
          </Select>
        </div>
        <div className={styles.btns}>
          <Button type="primary" style={{marginRight: '16px'}} onClick={()=>search()}>查询</Button>
          <Button onClick={reset}>重置</Button>
        </div>
      </div>
      <Table className='table' rowKey={record=>record.id} loading={props.tableLoding} dataSource={dataSource} columns={columns} pagination={pageOption} />
      {/* 取消议价确认框 */}
      <Modal wrapClassName={styles.close} className={styles.modal} width={300} closable={false} visible={countermandShow} footer={null} centered onOk={countermandOk} onCancel={countermandCancel}>
        <div className={styles.title}>是否取消订单</div>
        <div className={styles.btn}>
          <Button onClick={countermandCancel}>取消</Button>
          <Button type="primary" onClick={countermandOk}>确认</Button>
        </div>
      </Modal>
      {/* 改价弹出框/议价记录弹出框公用 */}
      <Modal wrapClassName={styles.alter} className={styles.modal} visible={alterShow} width={600} footer={null} onOk={alterOk} onCancel={alterCancel}>
        <div className={styles.title} style={{display: isRecord ? 'none' : 'flex'}}>
          <div>当前已有{buyerNumber}位买家出价</div>
          <div style={{marginLeft: '50px'}}>当前出价：<span style={{color:'#ff3232'}}>￥{myGoPrice}</span></div>
        </div>
        <div className={styles.ranking}>
          <div className={styles.box}>
            <div>排名</div>
            <div>用户昵称</div>
            <div>金额</div>
          </div>
          <div className={styles.content}>
            {
              singleRecord.map((item,index) => {
                return (
                  <div className={styles.box} key={index}>
                    <div>{item.ranking}</div>
                    <div>{item.nickname}</div>
                    <div>￥{item.price}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.go_price} style={{display: isRecord ? 'none' : 'block'}}>
          <Input ref={input} onChange={goPriceChange} placeholder="请输出你想出的单价" style={{width: '200px',marginRight: '32px'}} />
          <Button type="primary" onClick={alterOk}>确定</Button>
        </div>
      </Modal>
    </div>
  )
};

export default Content