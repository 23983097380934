import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Popover, Carousel } from 'antd';

const HeardNav = (props) => {
  const access_token = localStorage.getItem("access_token") // token
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
   // 顶部功能区数据
  const topFunction = ['登录注册','我的竞拍','买家中心'];
  const [topList, setTopList] = useState(topFunction)
  useEffect(()=>{
    if (access_token) {
      topFunction[0] = React.$axios.iphoneHide(access_user.mobile)
      setTopList(topFunction)
    }
  },[0])
  // 手机版二维码
  const phoneCode = (
    <div>
      <img style={{width:'200px',height:'200px'}} src="https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_xiazai.png"></img>
    </div>
  );

  // 调取父组件方法跳转后台页面
  const goLoginBidding = (index) => {
	  props.goLoginBidding(index)
  };

  const goBackHome = () => {
    props.goBackHome()
  }
  return (
    <div className={styles.heard_nav}>
      {/* 顶部功能区 */}
      <div className={styles.function}>
        <div className={styles.box}>
          {topList.map((item,index)=>{
            return (
              <div className={styles.item} key={index} onClick={goLoginBidding.bind(this, index)}>
                <div className={styles.text}>{item}</div>
                <div className={styles.br}></div>
              </div>
            )
          })}
          <div className={styles.item}>
            <Popover content={phoneCode} style={{display:'flex'}}>
              <div className={styles.text}>手机版</div>
            </Popover>
          </div>
        </div>
      </div>
      {/* logo tab区 */}
      <div className={styles.logo_tab}>
        <img onClick={goBackHome} style={{cursor: 'pointer'}} src="https://resources.huanhuanhuishou.com/new_image/20210812/hhyp_pc_logo.png" />
      </div>
 
    </div>
  )
};

export default HeardNav