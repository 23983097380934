import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import { Table, Space, Statistic } from 'antd';
const { Countdown } = Statistic;

const MyBid = (props) => {
  const dataSource = props.myGoPrice
  const columns = [
    {
      title: '竞价标题',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '竞价单类型',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Space size="middle">
          <div>{type == '1' ? '暗拍专场' : '统拍专场'}</div>
        </Space>
      ),
    },
    {
      title: '截止时间（倒计时）',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <Countdown value={Date.now() + (item.countdown_time || 0) * 1000} onFinish={onFinish} valueStyle={countdownStyle} />
        </Space>
      ),
    },
    {
      title: '商品总数',
      dataIndex: 'total_number',
      key: 'total_number',
    },
    {
      title: '总价',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total_price) => (
        <Space size="middle">
          <span>{total_price?`￥${total_price}`:null}</span>
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <span style={{color: '#169BD5', cursor: 'pointer'}} onClick={()=>goDetail(item)}>详情</span>
        </Space>
      ),
    }
  ];
  // 跳转详情页
  const goDetail = (item) => {
    props.goDetail(item)
  };
  // 表格分页跳转
  const pageChange = () => {
    console.log('分页跳转');
  };
  // 分页配置
  const pageOption = {
    pageSize:5,
    total:props.myGoPrice.length,
    showTotal: ()=>{
      return '共 '+props.myGoPrice.length+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    }
  }
  // 倒计时结束
  const onFinish = () => {
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '14px',
    color: '#000',
    // fontWeight: 'bold',
  }
  return (
    <Table className='table' dataSource={dataSource} columns={columns} pagination={pageOption}></Table>
  )
};

export default MyBid