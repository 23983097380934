import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { Select, Button, DatePicker, Space, Input, Table, Tag, Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const { RangePicker } = DatePicker;

const Content = () => {
  const [dateValue,setDateValue] = useState();
  const [classValue,setClassValue] = useState();
  const [brandValue,setBrandValue] = useState();
  const [modelValue,setModelValue] = useState();
  const [goodsNumber,setGoodsNumber] = useState();
  const [IMEI,setIMEI] = useState();
  const [orderNumer,setOrderNumer] = useState();
  const input = React.createRef()
  const inputs = React.createRef()
  const inputss = React.createRef()
  const phoneCode = (
    <div>
      <img style={{width:'160px',height:'160px'}} src="https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_xiazai.png"></img>
    </div>
  );
  // 表格数据
  const dataSource = [
    // {
    //   key: '1',
    //   sku: '2021-9-6 18:17',
    //   grade: '小花',
    //   price: '120',
    //   repertor: '5'
    // },
    // {
    //   key: '2',
    //   sku: '2021-9-6 18:17',
    //   grade: '大花',
    //   price: '500',
    //   repertor: '5'
    // },
    // {
    //   key: '3',
    //   sku: '2021-9-6 18:17',
    //   grade: '大花',
    //   price: '500',
    //   repertor: '5'
    // },
    // {
    //   key: '4',
    //   sku: '2021-9-6 18:17',
    //   grade: '大花',
    //   price: '500',
    //   repertor: '5'
    // },
    // {
    //   key: '5',
    //   sku: '2021-9-6 18:17',
    //   grade: '大花',
    //   price: '500',
    //   repertor: '5'
    // },
    // {
    //   key: '6',
    //   sku: '2021-9-6 18:17',
    //   grade: '大花',
    //   price: '500',
    //   repertor: '5'
    // },
  ];
  const columns = [
    {
      title: '物品信息',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: '单价',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: '售后申请',
      dataIndex: 'price',
      key: 'price',
      render: (price) => (
        <Space size="middle">
          <span>￥{price}</span>
        </Space>
      ),
    },
    {
      title: '状态',
      dataIndex: 'repertor',
      key: 'repertor',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: () => (
        <Space size="middle">
          <Link to={`/backStage/biddingSessiont/detail`} replace>
            <span style={{color: '#169BD5', cursor: 'pointer'}}>详情</span>
          </Link>
          <span style={{color: '#169BD5', cursor: 'pointer'}}>导出</span>
        </Space>
      ),
    },
  ];
  // 分页配置
  const pageOption = {
    pageSize:5,
    total:20,
    showTotal: ()=>{
      return '共 '+6+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    }
  }
  // 创建起始时间筛选
  const dateChange = (date) => {
    console.log('修改日期',date);
    setDateValue(date)
  };
  // 品类筛选
  const classChange = (value) => {
    setClassValue(value)
    console.log('选择的竞价类型' + value);
  };
  // 品牌筛选
  const brandChange = (value) => {
    setBrandValue(value)
    console.log('选择的竞价类型' + value);
  };
  // 型号筛选
  const modelChange = (value) => {
    setModelValue(value)
    console.log('选择的竞价类型' + value);
  };
  // 物品编号输入
  const goodsChange = (e) => {
    setGoodsNumber(e.target.defaultValue)
  };
  // IMEI输入
  const IMEIChange = (e) => {
    setIMEI(e.target.defaultValue)
  };
  // 售后单号输入
  const orderChange = (e) => {
    setOrderNumer(e.target.defaultValue)
  };
  // 重置筛选项
  const reset = () => {
    setDateValue()
    setClassValue()
    setBrandValue()
    setModelValue()
    setOrderNumer('')
    input.current.state.value = ''
    inputs.current.state.value = ''
    inputss.current.state.value = ''
  };
  // 表格分页跳转
  const pageChange = () => {
    console.log('分页跳转');
  };
  return (
    <div>
      <div className={styles.filter_box}>
        {/* 申请时间 */}
        <div className={styles.brand_box}>
          <div>申请时间：</div>
          <RangePicker value={dateValue} onChange={dateChange} />
        </div>
        {/* 物品编号 */}
        <div className={styles.brand_box}>
          <div>物品编号：</div>
          <Input ref={input} placeholder="请输入物品编号" style={{width: '200px'}} onChange={goodsChange} />
        </div>
        {/* IMEI */}
        <div className={styles.brand_box}>
          <div>IMEI：</div>
          <Input ref={inputs} placeholder="请输入IMEI" style={{width: '200px'}} onChange={IMEIChange} />
        </div>
        {/* 售后单号 */}
        <div className={styles.brand_box}>
          <div>售后单号：</div>
          <Input ref={inputss} placeholder="请输入售后单号" style={{width: '200px'}} onChange={orderChange} />
        </div>
        {/* 品类 */}
        <div className={styles.brand_box}>
          <div>品类：</div>
          <Select value={classValue} placeholder='请选择品类' style={{ width: 130 }} onChange={classChange}>
          
          </Select>
        </div>
        {/* 品牌 */}
        <div className={styles.brand_box}>
          <div>品牌：</div>
          <Select value={brandValue} placeholder='请选择品牌' style={{ width: 130 }} onChange={brandChange}>
            
          </Select>
        </div>
        {/* 型号 */}
        <div className={styles.brand_box}>
          <div>型号：</div>
          <Select value={modelValue} placeholder='请选择型号' style={{ width: 130 }} onChange={modelChange}>
            
          </Select>
        </div>
        <div className={styles.btns}>
          <Button type="primary" style={{marginRight: '16px'}}>查询</Button>
          <Button onClick={reset}>重置</Button>
        </div>
      </div>
      <div className={styles.warning}>
        <Tag color="warning">当前筛选条件下共<span> 0 </span>件商品</Tag>
        <div className={styles.tag}>
          <Tag icon={<ExclamationCircleOutlined />} color="warning">目前退货/售后单功能已全部迁移至优品拍拍APP</Tag>
          <Popover content={phoneCode} style={{display:'flex'}}><div className={styles.text}>下载APP</div></Popover>
        </div>
      </div>
      <Table className='table' dataSource={dataSource} columns={columns} pagination={pageOption} />
    </div>
  )
};

export default Content