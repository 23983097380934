import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import * as api from "../../../api/index"; // 引入api
import Content from './components/content';

import { Tabs, message } from 'antd';
const { TabPane } = Tabs;

const OrderList = (props) => {
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const [num,setNum] = useState(0)
  const [tabs,setTabs] = useState('WAIT_PAY')
  const [orderList,setOrderList] = useState([])
  const [tableLoding, setTableLoding] = useState(false);
  const [defaultActive, setDefaultActive] = useState('WAIT_PAY')
  useEffect(()=>{
    if (!access_user) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    let key = props.location.status?.tabs || 'WAIT_PAY'
    setTabs(key)
    getOrderList(key)
  },[num])
  // 标签页切换
  const callback = (key) => {
    setTableLoding(true)
    setTabs(key)
    getOrderList(key)
  };
  // 获取订单列表
  const getOrderList = (state) => {
    let params = {
      query: {
        order_status: state
      },
      page: 1,
      limit: 99999999999
    }
    api.orderListApi.getTable(params).then(res=>{
      if (res.code === 200) {
        setOrderList(res.data)
        setTableLoding(false)
      }
    })
  };
  // 跳转订单详情页
  const goDetail = (item) => {
    console.log(item);
    props.history.push({pathname: '/backStage/orderDetailTable', status: {id: item.id, tabs: tabs, type: item.type}})
  };
  return (
    <div className={styles.order_list}>
      {/* 标签页 */}
      <Tabs activeKey={tabs} onChange={callback}>
        <TabPane tab="待付款" key="WAIT_PAY">
          <Content orderList={orderList} tabs={tabs} tableLoding={tableLoding} getOrderList={getOrderList} setTableLoding={setTableLoding} goDetail={goDetail} />
        </TabPane>
        <TabPane tab="待发货" key="PAY">
          <Content orderList={orderList} tabs={tabs} tableLoding={tableLoding} getOrderList={getOrderList} setTableLoding={setTableLoding} goDetail={goDetail} />
        </TabPane>
        <TabPane tab="待收货" key="DISPATCHED">
          <Content orderList={orderList} tabs={tabs} tableLoding={tableLoding} getOrderList={getOrderList} setTableLoding={setTableLoding} goDetail={goDetail} />
        </TabPane>
        <TabPane tab="已失效" key="INVALIDATION">
          <Content orderList={orderList} tabs={tabs} tableLoding={tableLoding} getOrderList={getOrderList} setTableLoding={setTableLoding} goDetail={goDetail} />
        </TabPane>
        <TabPane tab="交易完成" key="FINISHED">
          <Content orderList={orderList} tabs={tabs} tableLoding={tableLoding} getOrderList={getOrderList} setTableLoding={setTableLoding} goDetail={goDetail} />
        </TabPane>
      </Tabs>
    </div>
  )
};

export default OrderList