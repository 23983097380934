import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index"; // 引入api
import Content from './components/content';
import MyBid from './components/myBid';
import History from './components/history';


import { Tabs, message } from 'antd';
const { TabPane } = Tabs;

const BiddingSession = (props) => {
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const [num,setNum] = useState(0);
  const [auctionId,setAuctionId] = useState();
  const [activeState,setActiveState] = useState();
  const [tabs,setTabs] = useState('1');
  const [tableData,setTableData] = useState([]);
  const [TPMessage,setTPMessage] = useState('');
  const [myGoPrice,setMyGoPrice] = useState([])
  const [historyList,setHistoryList] = useState([])
  const [historyFilter,setHistoryFilter] = useState({})
  const [tableLoading,setTableLoading] = useState(false)
  let aId
  useEffect(()=>{
    let tabs = props.location.status?.tabs || '1'
    setTabs(tabs)
    callback(tabs)
  },[props]);
  // 获取首页场次列表数据
  const getHomeData = () => {
    api.biddingSessionApi.getHome({}).then(res=>{
      if (res.code === 200) {
        setAuctionId(res.data.goodsUnified?.auction_id || '')
        aId = res.data.goodsUnified?.auction_id || ''
        setTPMessage(res.data.goodsUnified?.start_time_str || '')
        setActiveState(res.data.goodsUnified?.active_state || '')
        console.log(res.data.auction,'res.data.auction');
        if (res.data.auction.active_state == 0 || res.data.auction.active_state == 2) {
          setTableData([])
        } else {
          setTableData([res.data.auction])
        }
      }
    })
  };
  // 获取首页场次列表数据
  const getHomeDatas = () => {
    api.biddingSessionApi.getHome({}).then(res=>{
      if (res.code === 200) {
        setAuctionId(res.data.goodsUnified?.auction_id || '')
        aId = res.data.goodsUnified?.auction_id || ''
        getGoodsUnified()
        setTPMessage(res.data.goodsUnified?.start_time_str || '')
        setActiveState(res.data.goodsUnified?.active_state || '')
      }
    })
  };
  // 获取统拍专场场次数据
  const getGoodsUnified = () => {
    api.biddingSessionApi.goodsUnified({auction_id: auctionId || aId, page: 1, limit: 9999999999999}).then(res=>{
      if (res.code === 200) {
        setTableData(res.data)
      }
    })
  };
  // 标签页切换
  const callback = (key) => {
    setTabs(key)
    if (key == '2') {
      console.log(111111);
      getHomeDatas()
    } else if (key == '1') {
      getHomeData()
    } else if (key == '3')  {
      getMyGoPrice()
    } else {
      getHistoryList()
      getHistoryFilter()
    }
  };
  // 获取我的出价列表数据
  const getMyGoPrice = () => {
    api.biddingSessionApi.myGoPrice({}).then(res=>{
      console.log(res);
      if (res.code === 200) {
        setMyGoPrice(res.data)
      }
    })
  };
  // 获取历史出价列表数据
  const getHistoryList = (time,type) => {
    setTableLoading(true)
    api.biddingSessionApi.getHistoryList({page: 1, limit: 999999999, time, type }).then(res=>{
      if (res.code === 200) {
        setHistoryList(res.data)
        setTableLoading(false)
      }
    })
  };
  // 获取历史出价筛选数据
  const getHistoryFilter = () => {
    api.biddingSessionApi.getTimeType({}).then(res=>{
      console.log(res);
      if (res.code === 200) {
        setHistoryFilter(res.data)
      }
    })
  };
  // 跳转专场详情页
  const goDetail = (item) => {
    console.log(item,'item');
    props.history.push({
      pathname:'/backStage/biddingSessiont/detail',
      state:{
        id: item.id,
        types: tabs,
        states: item.active_state === undefined ? item.state : item.active_state,
        titles: item.title,
        kinds: item.type || ''
      }      
    })
    // props.history.push('/backStage/biddingSessiont/detail')
  };

  return (
    <div className={styles.bidding_session}>
      {/* 标签页 */}
      <Tabs activeKey={tabs} onChange={callback}>
        <TabPane tab="暗拍竞价中" key="1">
          <Content tabs={tabs} tableData={tableData} TPMessage={TPMessage} key='0' goDetail={goDetail} />
        </TabPane>
        <TabPane tab="统拍竞价中" key="2">
          <Content tabs={tabs} activeState={activeState} tableData={tableData} TPMessage={TPMessage} key='1' goDetail={goDetail} />
        </TabPane>
        <TabPane tab="我的出价" key="3">
          <MyBid myGoPrice={myGoPrice} goDetail={goDetail} />
        </TabPane>
        <TabPane tab="历史出价" key="4">
          <History tableLoading={tableLoading} tabs={tabs} historyList={historyList} historyFilter={historyFilter} setTableLoading={setTableLoading} getHistoryList={getHistoryList} goDetail={goDetail} />
        </TabPane>
      </Tabs>
    </div>
  )
};

export default BiddingSession