import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Checkbox, Modal, Form, Input, Button, Cascader, message, Tag  } from 'antd';
import  city  from "../../../common/cities.js"    //引入cities.js地址库文件
import * as api from "../../../api/index.js"; // 引入api
const OrderDetails = (props) => {
    // 地址列表
    const [addressList, setAddressList] = useState([])
	const [cityList, setCityList] = useState({})
	const [num, setNum] = useState(0)
	const [dataSource, setDataSource] = useState([])
	const [totalAmount, setTotalAmount] = useState(0)
	const [addressId,setAddressId] = useState()
	const form = React.createRef()
	console.log(form, Form)
	// 地址表单数据
	let list = {
		name: '',
		mobile: '',
		city: [],
		address: '',
		is_default: false
	}
    const [formList, setFormList] = useState(list)
	// 选中地址下标
	const [addressIndex, setAddressIndex] = useState(0)
    // 切换选中下标
	const selectedAddress = (index) => {
		setAddressIndex(index)
		setType(false)
	}
	// 检查手机号
    const checkPhone = (mobile) => {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(mobile);
    };

	let obj_state = localStorage.getItem("orderDetails_state_yppp")
	if (!props.location.state && obj_state) {
		props.location.state = JSON.parse(obj_state)
	}

	// 进入页面加载
	useEffect(()=>{
	    localStorage.setItem("orderDetails_state_yppp", JSON.stringify(props.location.state))
		setDataSource(props.location.state.data)
	    setTotalAmount(props.location.state.totalAmount)
		// 地址列表
		api.loginApi.userAddress().then((res) => {
			if (res.code === 200) {
				setAddressList(res.data)
				
			} else {
				message.info(res.msg);
			}
		})
	},[num])

	// 控制添加地址弹框
	const [visible, setVisible] = useState(false)
	const [type, setType] = useState(true)

	// 开具电子发票多选框改变时
	const onChange = (e) => {
	    console.log(`checked = ${e.target.checked}`);
	}

	// 添加地址
    const setVisibleStatus = () => {
        setVisible(true)
		setType(true)
	}
    
	// 修改地址
	const setAddressStatus = () => {
		console.log('11111')
	}

	// 修改地址
	const modifyVisibleStatus = (index, item) => {
		console.log(item)
		setAddressId(item.id)
		// 表单默认赋值
		form.current.setFieldsValue({
			name: item.name,
			mobile: item.mobile,
			city: [item.province, item.city, item.area],
			address: item.address,
			is_default: item.is_default == 2 ? true : false
		})
		// 赋值给地址框
		setCityList({
			province: item.province,
			city: item.city,
			area: item.area
		})
		setType(false)
		setVisible(true)
	}

	// 删除地址
	const delVisibleStatus = (index, item) => {
        console.log(index, item)
		Modal.confirm({
			centered: true,
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '确定删除地址吗?',
			okText: '确认',
			cancelText: '取消',
			onOk() {
				let params = {
					address_id: item.id
				}
			    api.loginApi.deleteUserAddress(params).then((res) => {
					if (res.code === 200) {
						message.info('删除成功!')
						setNum(num + 1)
						setAddressIndex(0)
					} else {
						message.info(res.msg)
					}
				})
			},
		})
	}

	// 地址表单提交
	const onFinish = (values) => {
		console.log('Success:', values)
        if (!checkPhone(values.mobile)) return message.info('请输入正确的手机号码哦');
		let data = {
			name: values.name,
			mobile: values.mobile,
			address: values.address,
			is_default: values.is_default ? 2 : 1, // 是否为默认地址 1 否 2 是 默认值为 1
		}
        let params = Object.assign(data, cityList)
		if (type) {
			// 添加地址
			api.loginApi.addUserAddress(params).then((res) => {
				if (res.code === 200) {
					message.info('添加成功!')
					// 清空表单
					form.current.resetFields()
					setNum(num + 1)
					setVisible(false)
				} else {
					message.info(res.msg);
				}
			})
		} else {
			// 编辑地址
            params.address_id = addressId
			api.loginApi.updateUserAddress(params).then((res) => {
				if (res.code === 200) {
					message.info('修改成功!')
					// 清空表单
					form.current.resetFields()
					setNum(num + 1)
					setVisible(false)
				} else {
					message.info(res.msg);
				}
			})
		}
	}

	// 地址表单提交失败
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo)
	}

	const columns = [
		{
			title: '商品信息',
			dataIndex: 'goods_name',
			key: 'goods_name',
			align: 'center',
			render: (text, value) => {
				return (
				<div>{value.goods_name}&nbsp;&nbsp;{value.sku_desc}</div>
				)
			},
		},
		{
			title: '等级',
			dataIndex: 'grade',
			key: 'grade',
			align: 'center'
		},
		{
			title: '单价',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			render: (text) => {
				let amount = Number(text).toFixed()
				return (
				<div style={{ color: "#FF3232" }}>{amount}</div>
				)
			},
		},
		{
			title: '数量',
			dataIndex: 'num',
			key: 'num',
			align: 'center',
			render: () => <div>1</div>,
		},
		{
			title: '小计',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			render: (renders) => {
				let amount = Number(renders).toFixed()
				return <div>{1 * amount}</div>
			}
		},
	]

	// 省市区地址发生改变时
	const onCascaderChange = (value, selectedOptions) => {
	    console.log(value, selectedOptions);
		setCityList({
			province: selectedOptions[0].label,
			city: selectedOptions[1].label,
			area: selectedOptions[2].label
		})
	}

	// 提交订单
	const submitOrder = () => {
		console.log(dataSource)
		const ids = []
        dataSource.forEach(item => {
            ids.push(item.goods_item_id || item.id)
        })
        const cart_ids = ids.join(',')
		api.loginApi.submitOrderStore({goods_item_id: cart_ids}).then((res) => {
			if (res.code === 200) {
				message.info('订单提交成功!')
		        props.history.push({pathname: '/backStage/fixedPrice'})
			} else {
				message.info(res.msg);
			}
		})
	}

	function filter(inputValue, path) {
	    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
	}

	return (
		<div>
			{/* 选择收货地址 */}
			<div className={styles.shipping_address}>
				<div className={styles.address_title}>选择收货地址</div>
				<div className={styles.box_flex}>
					<div className={styles.address_info}>
						{addressList.map((item,index)=>{
							return(
								// className={[styles.address_box, addressIndex === index?styles.address_bg:styles.address_border].join(' ')}
								<div key={index} className={[styles.address_box, styles.address_border].join(' ')} onClick={selectedAddress.bind(this, index)}>
								   <div className={styles.address_city}>{item.province} {item.city} {item.area} ({item.name}收)</div>
								   <div className={styles.address_iphone}>{item.address}  {item.mobile}</div>
								   <div className={styles.modify}>
									   <span onClick={modifyVisibleStatus.bind(this, index, item)}>修改</span>
									   <span onClick={delVisibleStatus.bind(this, index, item)}>删除</span>
								   </div>
								</div>
							)
						})}

						<div className={styles.address_add } onClick={setVisibleStatus.bind()}>
							<PlusOutlined /> <span>添加收获地址</span>
						</div>
					</div>
				</div>
			</div>

			{/* 确认订单详情 */}
			<div className={styles.order_wrap}>
                <div className={styles.order_title}>确认订单详情</div>
				<div>
					<Table rowKey={dataSource => dataSource.id} dataSource={dataSource} columns={columns} />
				</div>
				<div className={styles.electronic_invoice}>
                    <div><Checkbox onChange={onChange}>开具电子发票</Checkbox ></div>
					<div className={styles.shipping_method}>运送方式：顺丰配送</div>
					<div className={styles.price}>0.00</div>
				</div>
				<div className={styles.total_price}>
					<div><span className={styles.text}>合计（含配送费）</span> <span className={styles.price}>{totalAmount}</span></div>
				</div>
				<div className={styles.submit_orider}>
                    <div className={styles.actual_payment}>
						<span>实付款：</span><span>￥{totalAmount}</span>
					</div>
					<div className={styles.address_box_border}>
					    {/* <div className={styles.order_border} style={{display: addressList.length == 0 ? 'none':'block'}}>
                           <div className={styles.send}><span>寄送至：</span>
						        {
									addressList && addressList.length !== 0 ? <span>{addressList[addressIndex].province || ''} {addressList[addressIndex].city} {addressList[addressIndex].area} {addressList[addressIndex].address}</span> : ''
								}
						   </div>
						   <div className={styles.send}><span>收货人：</span>
								{
									addressList && addressList.length !== 0 ? <span>{addressList[addressIndex].name} {addressList[addressIndex].mobile}</span> : ''
								}
						   </div>
						</div> */}
					</div>
					<div className={styles.order_sub}>
						<Tag icon={<ExclamationCircleOutlined />} color="warning">订单提交成功后请在优品拍拍APP完成支付</Tag>
						<span onClick={submitOrder} className={styles.submit_bnt}>提交订单</span>
					</div>
				</div>
			</div>

			{/* 添加地址弹窗 */}
			<Modal
			    forceRender
				title={type?"添加收货地址":"修改收货地址"}
				centered
				visible={visible}
				onOk={() => setVisible(false)}
				onCancel={() => setVisible(false)}
				footer={[]}
				wrapClassName={'web'}
				getContainer={document.getElementsByClassName('div')[0]}
				width={620}
			>
				<Form
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					ref={form} //这里注册一下ref
					initialValues={{
						name: formList.name
					}}
					>
					<Form.Item
						label="收货人"
						name="name"
						rules={[{ required: true, message: '请输入收货人姓名' }]}
					>
						<Input placeholder="请输入收货人姓名" />
					</Form.Item>

					<Form.Item
						label="手机号码"
						name="mobile"
						rules={[{ required: true, message: '请输入收货人号码' }]}
					>
						<Input placeholder="请输入收货人号码" />
					</Form.Item>

					<Form.Item
						label="所在区域"
						name="city"
						rules={[{ required: true, message: '请选择省市区等' }]}
					>
						<Cascader
							options={city}
							initialValues={formList.city}
							onChange={onCascaderChange}
							placeholder="请选择省市区等"
							showSearch={{ filter }}
						/>
					</Form.Item>

					<Form.Item
						label="详细地址"
						name="address"
						rules={[{ required: true, message: '请输入街道、楼牌号等' }]}
					>
						<Input placeholder="请输入街道、楼牌号等" />
					</Form.Item>

					<Form.Item name="is_default" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
						<Checkbox >设为默认地址</Checkbox>
					</Form.Item>

					<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
						<Button type="primary" htmlType="submit">
						   确定
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	)
};

export default OrderDetails