import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index"; // 引入api
import QC from '../../../components/QC';

import { Select, Button, Table, Space, Badge, Input, message, Modal, InputNumber } from 'antd';
import { ShoppingCartOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Item from 'antd/lib/list/Item';
const { Option } = Select;

const FixedPrice = (props) => {
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const [num,setNum] = useState(0)
  const [classValue,setClassValue] = useState();
  const [brandValue,setBrandValue] = useState();
  const [goodsValue,setGoodsValue] = useState();
  const [gradeValue,setGradeValue] = useState();
  const [goodsList,setGoodsList] = useState([]);
  const [modelShow,setModelShow] = useState(false);
  const [filterData,setFilterData] = useState([]);
  const [brandList,setBrandList] = useState([]);
  const [goodsFilterList,setGoodsFilterList] = useState([]);
  const [min,setMin] = useState();
  const [max,setMax] = useState();
  const [tableLoding,setTableLoding] = useState(true);
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  const [goodsId, setGoodsId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [goPrice,setGoPrice] = useState();
  const [bargain,setBargain] = useState({});
  const [cartNum, setCartNum] = useState(0);
  const input = React.createRef();
  const [pageSize, setPageSize] = useState(10)
  useEffect(()=>{
    getTableData(1);
    getFilterData();
    // 获取购物车角标数据
    if (access_user) {
       getCartData()
    }
  },[num])
  // 表格数据
  // const dataSource = goodsList;
  const columns = [
    {
      title: 'SKU信息',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle" style={{display:'block'}}>
          <div>{item.goods_name}</div>
          <div>{item.sku_desc}</div>
        </Space>
      ),
    },
    {
      title: '等级',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: '质检报告',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
        </Space>
      ),
    },
    {
      title: '一口价',
      dataIndex: 'price',
      key: 'price',
      render: (price) => (
        <Space size="middle">
          <span>￥{price}</span>
        </Space>
      ),
    },
    {
      title: '库存量',
      dataIndex: 'item_count',
      key: 'item_count',
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      align: 'left',
      render: (text, item, index) => (
        <Space size="middle">
          <span onClick={goOrderDetail.bind(this, item)} style={{color: '#FF3232', cursor: 'pointer'}}>立即购买</span>
          {
            item.is_cart == 0 ? (
            <span onClick={addShoppingCart.bind(this, item, index)} style={{color: '#169BD5', cursor: 'pointer'}}>加入购物车</span>):item.cart_id ? (
            <span onClick={removeShoppingCart.bind(this, item, index)} style={{color: '#169BD5', cursor: 'pointer'}}>移除购物车</span>
            ): (
              <span style={{color: '#999999'}}>当前商品正在交易</span>
            )
          }
          <span onClick={myBargain.bind(this, item)} style={{color: '#08CC7E', cursor: 'pointer', display: item.button_state == 0?'block':'none'}}>议价</span>
        </Space>  
      ),
    },
  ];
  // 获取购物车列表
  const getCartData = () => {
    let params = {
      page: 1,
      size: 999999
    }
    api.shoppingCartApi.cartList(params).then(res=>{
      if (res.code == 200) {
        setCartNum(res.data.length)
      }
    })
  };
  // 议价
  const myBargain = (item) => {
    if (!access_user) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    setBargain(item);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // 出价输入框
  const goPriceChange = (e) => {
    setTimeout(() => {
      setGoPrice(e.target.defaultValue)
    }, 1);
  };
  // 确认提交议价
  const alterOk = () => {
    if (!goPrice) {
      message.error('请输入价格且价格不能为0');
      return
    }
    api.fixedPriceApi.submitTalkPrice({price:goPrice,goods_item_id:bargain.id}).then(res=>{
      if (res.code == 200) {
        message.success('议价成功');
        setIsModalVisible(false);
        input.current.state.value = ''
      } else{
        message.info(res.msg);
      }
    })
  };
  // 获取列表数据
  const getTableData = (current, clear = true) => {
    if (clear) {
      var params = {
        page: 1,
        limit: 99999999999,
        query: {
          type: 0,
          class_id: classValue,
          brand_id: brandValue,
          goods_id: goodsValue === 0 ? '' : goodsValue,
          grade:gradeValue,
          price: {
            min: min,
            max: max
          },
          showcase:''
        },
      }
    } else {
      var params = {
        page: 1,
        limit: 99999999999,
        query: {
          type: 0,
          class_id: '',
          brand_id: '',
          goods_id: '',
          grade:'',
          price: {
            min: '',
            max: ''
          },
          showcase:''
        },
      }
    }

    console.log(params)
    api.fixedPriceApi.getTable(params).then(res=>{
      if (res.code == 200) {
        setGoodsList(res.data)
        setPaginationCurrent(current)
        console.log(paginationCurrent)
        setTableLoding(false)
      }
    })
  };
  // 获取筛选数据
  const getFilterData = () => {
    api.fixedPriceApi.getSearchData({showcase: 1, class_id: 0}).then(res=>{
      console.log(res);
      setFilterData(res.data.search)
    })
  };
  // 加入购物车
  const addShoppingCart = (value, index) => {
    let params = {
      goods_item_id: value.id
    }

    api.shoppingCartApi.addCart(params).then(res=>{
      if (res.code == 200) {
        getTableData(paginationCurrent)
        getCartData()
        message.info('加入购物车成功');
      } else {
        message.info(res.msg);
        if (res.code == '401') {
          props.history.push('/login')
        }
      }
    })
  };
  // 移除购物车
  const removeShoppingCart = (value, index) => {
    console.log(value, index)
    Modal.confirm({
			centered: true,
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '确定移除该商品吗?',
			okText: '确认',
			cancelText: '取消',
			onOk() {
				let params = {
					cart_ids: value.cart_id
				}
        let idx = (paginationCurrent - 1) * pageSize + index
        console.log(paginationCurrent, index, idx)
			  api.shoppingCartApi.deleteCartGoods(params).then((res) => {
					if (res.code === 200) {
            let list = goodsList.concat()
            list[idx].is_cart = 0
            setGoodsList(list)
            getCartData()
						message.info('移除成功!')
					} else {
						message.info(res.msg);
					}
				})
			},
		})
  };
  // 跳转订单详情付款页
  const goOrderDetail = (item) => {
    if (!access_user) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    item.amount = item.price
    props.history.push({pathname: '/backStage/orderDetails', state: {data: [item], totalAmount: item.price}})
  };
  // 跳转购物车页面
  const gocart = () => {
    if (!access_user) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    props.history.push('/backStage/biddingPrice')
  };
  // 打开质检报告弹窗
  const goQC = (item) => {
    setGoodsId(item.id)
    setModelShow(true)
  };
  // 关闭质检报告弹窗
  const closeQC = () => {
    setModelShow(false)
  };
  // 分页配置
  const pageOption = {
    pageSize: pageSize,
    total: goodsList.length,
    current: paginationCurrent,
    showTotal: ()=>{
      return '共 '+ goodsList.length +' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
      setPaginationCurrent(current)
    },
    onShowSizeChange: (current, pageSize) => {
      setPageSize(pageSize)
      console.log(current, pageSize)
    }
  }
  // 所属品牌筛选
  const brandChange = (value) => {
    setBrandValue(value)
    setGoodsValue()
    console.log('选择的品牌' + value);
    let params = {
      brand_id: value,
      class_id: classValue,
      showcase: '',
      type: 0
    }
    api.fixedPriceApi.getGoodsListNew(params).then(res=>{
      if (res.code === 200) {
        setGoodsFilterList(res.data)
      }
    })
  };
  // 所属品类筛选
  const classChange = (value) => {
    setClassValue(value)
    setBrandValue()
    setGoodsValue()
    setGoodsFilterList([])
    console.log('选择的品类' + value);
    filterData.model.map(item => {
      if (item.id === value) {
        setBrandList(item.list)
      }
    })
  };
  // 所属品类筛选
  const goodsChange = (value) => {
    setGoodsValue(value)
    console.log('选择的型号' + value);
  };
  // 等级筛选
  const gradeChange = (value) => {
    setGradeValue(value)
    console.log('选择的等级' + value);
  };
  // 最低价筛选
  const minChange = (value) => {
    console.log(value)
  //   if (!(/(^[1-9]\d*$)/.test(value.target.value))) { 
  //     setMin()
  //     return false; 
  // 　}
    setMin(value)
    // console.log('最低价' + value.target.value);
  };
  // 最高价筛选
  const maxChange = (value) => {
    setMax(value)
    console.log('最高价' + value);
  };
  // 重置筛选项
  const reset = () => {
    setBrandValue()
    setClassValue()
    setGradeValue()
    setGoodsValue()
    setMin()
    setMax()
    setTableLoding(true)
    getTableData(1, false)
  };
  // 查询
  const inquire = () => {
    console.log(min, max)
    if (min && !max) return message.info('请输入最高价');
    if (!min && max) return message.info('请输入最底价');
    console.log(Number(min), Number(max))
    if (Number(min) > Number(max)) return message.info('最高价需大于最底价');
    setTableLoding(true)
    getTableData(1)
  };
  // 表格分页跳转
  const pageChange = (current,pageSize) => {
    console.log('分页跳转', current, pageSize);
  };

  return (
    <div className={styles.fixed_price}>
      <QC goodsId={goodsId} modelShow={modelShow} closeQC={closeQC} />
      {/* 头部筛选 */}
      <div className={styles.head_box}>
        <div className={styles.title}>一口价专场</div>
        <div className={styles.filter_box}>
          {/* 所属品类 */}
          <div className={styles.brand_box}>
            <div>品类：</div>
            <Select value={classValue} placeholder='请选择' style={{ width: 130 }} onChange={classChange}>
              {
                filterData && filterData.model ? filterData.model.map( item => {
                  return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )
                }) : null
              }
            </Select>
          </div>
          {/* 所属品牌 */}
          <div className={styles.brand_box}>
            <div>品牌：</div>
            <Select value={brandValue} placeholder='请选择' style={{ width: 130 }} onChange={brandChange}>
              {
                brandList && brandList.length !== 0 ? brandList.map(item => {
                  return (
                    <Option key={item.brand_id} value={item.brand_id}>{item.brand_name}</Option>
                  )
                }) : null
              }
            </Select>
          </div>
          {/* 所属型号 */}
          <div className={styles.brand_box}>
            <div>型号：</div>
            <Select value={goodsValue} placeholder='请选择' style={{ width: 130 }} onChange={goodsChange}>
              {
                goodsFilterList && goodsFilterList.length !== 0 ? goodsFilterList.map(item => {
                  return (
                    <Option key={item.goods_id} value={item.goods_id}>{item.goods_name}</Option>
                  )
                }) : null
              }
            </Select>
          </div>
          {/* 等级 */}
          <div className={styles.brand_box}>
            <div>等级：</div>
            <Select value={gradeValue} placeholder='请选择' style={{ width: 130 }} onChange={gradeChange}>
              {
                filterData && filterData.quality ? filterData.quality.map(item=>{
                  return (
                    <Option key={item.key} value={item.key}>{item.value}</Option>
                  )
                }) : null
              }
            </Select>
          </div>
          {/* 所属型号 */}
          <div className={styles.brand_box}>
            <div style={{width: '100px'}}>价格区间：</div>
            <Input.Group compact>
              <InputNumber  style={{ width: 80, textAlign: 'center' }} min={1} value={min} onChange={minChange} placeholder="最低价" />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="~"
                disabled
              />
              <InputNumber 
                min={1}
                className="site-input-right"
                value={max}
                onChange={maxChange}
                style={{
                  width: 80,
                  textAlign: 'center',
                }}
                placeholder="最高价"
              />
            </Input.Group>
          </div>
          <div className={styles.btns}>
            <Button type="primary" style={{marginRight: '16px'}} onClick={inquire}>查询</Button>
            <Button onClick={reset}>重置</Button>
            <ShoppingCartOutlined onClick={gocart} style={{fontSize: '28px', marginLeft: '42px', color: '#333', cursor: 'pointer'}} />
            {
              cartNum ? (<Badge style={{marginBottom: '25px'}} count={cartNum}></Badge>):null
            }
          </div>
        </div>
      </div>
      {/* 表格展示 */}
      <div className={styles.table_box}>
        <Table rowKey={goodsList => goodsList.id} scroll={{ x: 1500, y: 500 }} loading={tableLoding} className='table' dataSource={goodsList} columns={columns} pagination={pageOption} />
      </div>
      {/* 议价弹窗 */}
      <Modal getContainer={false} title="我要议价" visible={isModalVisible} centered footer={[]} onOk={handleOk} onCancel={handleCancel}>
        <div className={styles.bargain_goods}>
          <span className={styles.grade_text}>{bargain.grade}</span> <span className={styles.goods_name}>{bargain.goods_name}</span>
        </div>
        <div className={styles.sku_desc}>{bargain.sku_desc}</div>
        <div className={styles.color_price}>￥{bargain.price}</div>
        <div className={styles.go_price}>
          <Input ref={input} onChange={goPriceChange} placeholder="请输出你的预期价格" style={{width: '200px',marginRight: '32px'}} />
          <Button type="primary" onClick={alterOk}>确定</Button>
        </div>
      </Modal>
    </div>
  )
}

export default FixedPrice