import config from './config.js';

const loginApi = {
	//  登陆
    login: (params) => {
		return config.ajaxs({
		  url: 'api/user/login',
		  params,
		  methods: 'post'
		})
	},
	// 发送验证码
    sendPhoneCode: (params) => {
    	return config.ajaxs({
    	  url: 'api/User/sendPhoneCode',
    	  params,
    	  methods: 'post'
    	})
    },
	// 地址信息
	userAddress: (params) => {
    	return config.ajaxs({
    	  url: 'api/userAddress/index',
    	  params,
    	  methods: 'post'
    	})
    },
	// 添加地址信息
	addUserAddress: (params) => {
    	return config.ajaxs({
    	  url: 'api/userAddress/store',
    	  params,
    	  methods: 'post'
    	})
    },
	// 编辑地址信息
	updateUserAddress: (params) => {
    	return config.ajaxs({
    	  url: 'api/userAddress/update',
    	  params,
    	  methods: 'post'
    	})
    },
	// 删除地址信息
	deleteUserAddress: (params) => {
    	return config.ajaxs({
    	  url: 'api/userAddress/delete',
    	  params,
    	  methods: 'post'
    	})
    },
	// 提交订单
	submitOrderStore: (params) => {
    	return config.ajaxs({
    	  url: 'api/Order/store',
    	  params,
    	  methods: 'post'
    	})
    },
};
export default loginApi;