import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import BackStage from './pages/backStage';
import News from './pages/page/newDetails';
import Article from './pages/page/article';
import AuthRoute from './router/authRoute';
import './App.css';
import 'antd/dist/antd.css';
function App() {
  return (
    <div className="App">
      {/* <Router>
        <Switch>
          {
            routes.map((item, index) => {
              return (
                <AuthRoute key={ index } routeData={ routes }></AuthRoute>
              )
            })
          }
        </Switch>
      </Router> */}
      <Switch>
        <Route path='/login' component={Login}></Route>
        <Route path='/home' component={Home}></Route>
        <Route path='/article' component={Article}></Route>
        <Route path='/news/:id' component={News}></Route>
        <Route path='/backStage' component={BackStage}></Route>
        <Route path='/' component={Home}></Route>
        <Route></Route>
        <Route></Route>
      </Switch>
    </div>
  );
}

export default App;
