import config from './config.js';

// 一口价专场
const biddingSessionApi = {
  // 获取首页数据场次数据
  getHome: (params) => {
		return config.ajaxs({
		  url: 'api/Home/homeIndex',
		  params,
		  methods: 'post'
		})
	},
  // 获取统拍专场场次数据
  goodsUnified: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/index',
		  params,
		  methods: 'post'
		})
	},
  // 获取专场场次详情数据
  getDetails: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/getDetails',
		  params,
		  methods: 'post'
		})
	},
  // 获取统拍专场场次商品数据列表
  getGoodsList: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/getGoodsList',
		  params,
		  methods: 'post'
		})
	},
	// 获取暗拍专场场次商品数据列表
  getGoodsLists: (params) => {
		return config.ajaxs({
		  url: 'api/DarkPat/getGoodsList',
		  params,
		  methods: 'post'
		})
	},
	// 获取暗拍筛选型号
	goodsTypes: (params) => {
		return config.ajaxs({
		  url: 'api/DarkPat/goodsTypes',
		  params,
		  methods: 'post'
		})
	},
	// 获取统拍筛选型号
	goodsTypess: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/goodsTypes',
		  params,
		  methods: 'post'
		})
	},
  // 获取暗拍筛选数据
  getClassData: (params) => {
		return config.ajaxs({
		  url: 'api/DarkPat/getClassData',
		  params,
		  methods: 'post'
		})
	},
	// 获取统拍筛选数据
  getClassDatas: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/getClassData',
		  params,
		  methods: 'post'
		})
	},
	// 获取专场场次attr
  getGoodsAttr: (params) => {
		return config.ajaxs({
		  url: 'api/Home/getGoodsAttr',
		  params,
		  methods: 'post'
		})
	},
	// 暗拍出价
  save: (params) => {
		return config.ajaxs({
		  url: 'api/PriceOffer/save',
		  params,
		  methods: 'post'
		})
	},
	// 统拍出价
  offerPrice: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/offerPrice',
		  params,
		  methods: 'post'
		})
	},
	// 我的出价
	myGoPrice: (params) => {
		return config.ajaxs({
		  url: 'api/PriceOffer/index',
		  params,
		  methods: 'post'
		})
	},
	// 我的出价 暗拍列表
	getPriceOfferList: (params) => {
		return config.ajaxs({
		  url: 'api/PriceOffer/getPriceOfferList',
		  params,
		  methods: 'post'
		})
	},
	// 我的出价 统拍列表
	getUnifiedList: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsUnified/getUnifiedList',
		  params,
		  methods: 'post'
		})
	},
	// 获取历史出价数据
	getHistoryList: (params) => {
		return config.ajaxs({
		  url: 'api/PriceOffer/getHistoryList',
		  params,
		  methods: 'post'
		})
	},
	// 获取历史出价筛选数据
	getTimeType: (params) => {
		return config.ajaxs({
		  url: 'api/PriceOffer/getTimeType',
		  params,
		  methods: 'post'
		})
	},
	// 获取等级筛选数据
	getGradeByGoods: (params) => {
		return config.ajaxs({
		  url: 'api/Home/getGradeByGoods',
		  params,
		  methods: 'post'
		})
	},
};

export default biddingSessionApi;