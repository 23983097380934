import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index.js"; // 引入api
import { RightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import HeardNav from './components/heardNav';
import Footer from '../../home/components/footer';
import avatarSmall from '../../../static/emptyIcon.png'

const NewDetails = (props) => {
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const access_token = localStorage.getItem("access_token") // token
  const [info, setInfo] = useState({}) 
  const [content, setContent] = useState()
  const [num, setNum] = useState(0)
  const [ids, setIds] = useState(0) //前者是用来获取的，后者是用来修改的
  // 进入页面加载
  useEffect(()=>{
		// ids 表示详情页切换
		if(ids){
			props.match.params.id = ids 
			props.history.replace(`/news/${ids}`)
		} 
    const { id } = props.match.params  
    api.homeApi.articleInfo({id}).then(res=>{
      if (res.code === 200) {
        setInfo(res.data)
        const filters = html2Escape(res.data.info.content).replace(/style=""/g, '')
        const getFilters = filters.replace(/<img /g, "<img class='imgs'  onerror=onerror=null;src='https://resources.huanhuanhuishou.com/global_source/57A01545-CD4E-BC8A-0479-0D4DDA52CDEA.png' style='display: block; margin: 0 auto; max-width: 100%'")
        setContent(getFilters)
      } else {
        message.info(res.msg);
        if (res.code == '401') {
          props.history.push('/login')
        }
      }
    })
  },[num])
  const html2Escape = (str) => {
    var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
    str.replace(/<figure class="image">/g, '').replace(/<\/figure>/g, '')
    return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
    return arrEntities[t]
    })
  }

  const joinNews = (item) => {
		setIds(item.id)
    setNum(num + 1) //更新
    window.scrollTo(0, 0);
  }

  // 头部登录，竞拍，买家中心
  const goLoginBidding = (index) => {
    if (!access_token) {
	    props.history.push('/login')
      return false
    }
    if (index == 1 || index == 2) {
        props.history.push({pathname: '/backStage/orderList', status: {key: '31', menu: '3', tabs: 'WAIT_PAY'}})
    } else if (index == 0) {
        props.history.push({pathname: '/backStage/userCenter', status: {key: '11', menu: '1'}})
    } else {
	    props.history.push('/login')
    }
  };

  const goBackHome = () => {
      props.history.push('/home')
  }

  const goArticle = () => {
      props.history.push('/article')
  }
  
  return (
    <div>
      {/* 头部导航区 */}
      {
       <HeardNav goBackHome={goBackHome} goLoginBidding={goLoginBidding} />
      }

      {/* 资讯列表 */}
      <div className={styles.wrap_list}>
            <div className={styles.news_list}>
                <div className={styles.breadcrumb}>
                    <span className={styles.cursor} onClick={goBackHome}>首页</span><span><RightOutlined className={styles.light} /></span><span className={styles.cursor} onClick={goArticle}>热门资讯</span><span><RightOutlined className={styles.light} /></span><span className={styles.bread_color}>内容详情</span>
                </div>
            </div>

            <div className={styles.newsC}>
                {/* 热门资讯 */}
                <div className={styles.navigation}>
                    <div className={styles.hotLeft}>
                        <div className={styles.hotList}>
                            <h5>热门资讯</h5>
                        </div>
                    </div>
                    <div className={styles.hotListBox}>
                        {
                            info && info.articles ? info.articles.map((item, index) => {
                                return (
                                   <div className={styles.hotLli} key={index} onClick={joinNews.bind(this, item)}>{item.title}</div>
                                )
                            }): null
                        }
                    </div>
                </div>
                <div className={styles.newsCRight}>
                   <div className={styles.newsCRightTop}>
                        {
                            info && info.info ? (
                                <h1 className={styles.newsCRHeader}>{info.info.title}</h1>
                            ):null
                        }

                        {
                            info && info.info ? (
                                <div className={styles.timefrom}>
                                    <span>点击量:  {info.info.reading}</span>
                                    <span>{info.info.create_time}</span>
                                </div>
                            ):null
                        }
                       
                       <div className={styles.context} dangerouslySetInnerHTML={{__html:content}}></div>
                   </div>
                   <div style={{height: '20px'}}></div>
                   <div className={styles.updown}>
                        <div className={styles.con}>
                            <div className={styles.aboutInfo}>
                                <div className={styles.information}>相关资讯 :</div>
                                <div className={styles.ul}>
                                    {
                                        info && info.articles ? info.articles.map((item, index) => {
                                            return (
                                                <div className={styles.h2} key={index} onClick={joinNews.bind(this, item)}>
                                                    <div className={styles.name}>{item.title}</div>
                                                    <div className={styles.date}>{item.create_time}</div>
                                                </div>
                                            )
                                        }):null
                                    }
                                </div>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
      </div>

      {/* 底部 */}
      <Footer />
    </div>
  )
};

export default NewDetails;
