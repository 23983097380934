import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';// 引入观察者
import { toJS } from 'mobx';// 引入解析mobx对象
import styles from './index.module.scss'
import HeardNav from './components/heardNav';
import Whisper from './components/whisper';
import Picker from './components/picker';
import Special from './components/special';
import News from './components/news';
import * as api from "../../api/index"; // 引入api
import Footer from './components/footer';
import { message } from "antd";

const Home = (props) => {
   const [num,setNum] = useState(0); // 保证useEffect执行一次
   const [homeData,setHomeData] = useState(); 
   const [goodsItem,setGoodsItem] = useState([]);
   const [orderCategory,setOrderCategory] = useState([]);
   const access_token = localStorage.getItem("access_token") // token
   const [pageScroll, setPageScroll] = useState(0)
  // const { HomeStore: { accountRoll } } = props;
  // let data = accountRoll.slice().map(item=>{
  //   item = toJS(item);
  //   return item;
  // });
  useEffect(()=>{
    getHomeIndex()
    getTable()
    setTimeout(() => {
      getOrderCategory()
    },300)
    window.addEventListener("scroll", bindHandleScroll)
    bindHandleScroll()
  },[num])
  // 监听页面滑动
  const bindHandleScroll = (event) => {
    let scrollY = window.scrollY
    if (scrollY > 500) {
      setPageScroll(true)
    } else {
      setPageScroll(false)
    }
  }
  // 首页数据
  const getHomeIndex = () => {
    api.homeApi.homeIndex().then((res) => {
      if (res.code == 200) {
        setHomeData(res.data)
      } else {
        message.info(res.msg);
      }
    })
  }
  // 一口价专场
  const getTable = () => {
    let params = {
      limit: 4,
      page: 1,
      query: {
        showcase: 2,
        type: 0
      }
    }
    api.homeApi.getTable(params).then((res) => {
      if (res.code == 200) {
        setGoodsItem(res.data)
      } else {
        message.info(res.msg);
      }
    })
  }
  // 订单数量
  const getOrderCategory = () => {
    api.homeApi.orderCategory().then((res) => {
      if (res.code == 200) {
        res.data.forEach((element, index) => {
          // 不展示我的出价
          if (element.type == 'MyBid') {
            res.data.splice(index, 1)
          }
        });
        setOrderCategory(res.data)
      } else {
        message.info(res.msg);
      }
    })
  }
  // 跳转后台管理页面
  const goBackStage = (item) => {
    // 我的出价
    if (item.type == "MyBid") {
      props.history.push({pathname: '/backStage/biddingSession', status: {key: '21', menu: '2', tabs: '3'}})
    } else if (item.type == "WAIT_PAY") {
      // 待付款
      props.history.push({pathname: '/backStage/orderList', status: {key: '31', menu: '3', tabs: 'WAIT_PAY'}})
    } else if (item.type == "BEING_PROCESSED") {
      // 待发货
      props.history.push({pathname: '/backStage/orderList', status: {key: '31', menu: '3', tabs: 'PAY'}})
    } else if (item.type == "DISPATCHED") {
      // 待收货
      props.history.push({pathname: '/backStage/orderList', status: {key: '31', menu: '3', tabs: 'DISPATCHED'}})
    } else if (item.type == "BARGAIN") {
      // 我的议价
      props.history.push({pathname: '/backStage/negotiatedPrice', status: {key: '33', menu: '3'}})
    }
  };
  // 头部登录，竞拍，买家中心
  const goLoginBidding = (index) => {
    if (!access_token) {
	    props.history.push('/login')
      return false
    }
    if (index == 1 || index == 2) {
      props.history.push({pathname: '/backStage/orderList', status: {key: '31', menu: '3', tabs: 'WAIT_PAY'}})
    } else if (index == 0) {
      props.history.push({pathname: '/backStage/userCenter', status: {key: '11', menu: '1'}})
    } else {
	    props.history.push('/login')
    }
  };

    // 暗拍专场
  const joinSecretShot = () => {
    if (!access_token) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    props.history.push({pathname: '/backStage/biddingSession', status: {key: '21', menu: '2'}})
  }
  // 一口价
  const joinOnePrice = () => {
    if (!access_token) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    props.history.push({pathname: '/backStage/fixedPrice', status: {key: '22', menu: '2'}})
  }
  // 统拍
  const joinUnified = () => {
    if (!access_token) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    props.history.push({pathname: '/backStage/biddingSession', status: {key: '21', menu: '2', tabs: '2'}})
  }
  // 资讯
  const joinDetails = (item) => {
    props.history.push(`/news/${item.id}`)
  }
  // 资讯列表
  const goArticle = () => {
    props.history.push({pathname: '/article'})
  }
  return (
    <div className={styles.home}>
      {/* 头部导航区 */}
      {
        homeData && homeData.banner ? (<HeardNav banner={homeData.banner} orderCategory={orderCategory} goBackStage={goBackStage} goLoginBidding={goLoginBidding} />):null
      }
      {/* 暗拍专场 */}
      {
        homeData && homeData.auction ? (
          <Whisper auction={homeData.auction} joinSecretShot={joinSecretShot} />
        ):null
      }
      {/* 统货专场 */}
      <Picker joinUnified={joinUnified} />
      {/* 一口价专场 */}
      {
        goodsItem ? (<Special goodsItem={goodsItem} joinOnePrice={joinOnePrice} />) : null
      }

      {/* 新闻资讯 */}
      <News joinDetails={joinDetails} goArticle={goArticle} />
      <img className={styles.footer_img} style={{bottom:pageScroll?'0':null}} src="https://resources.huanhuanhuishou.com/global_source/D90C2D57-C94E-8639-8C3F-D53192378D79.png" />
      {/* 页脚 */}
      <Footer joinDetails={joinDetails} />
    </div>
  )
};

export default inject('HomeStore')(observer(Home));