import config from './config.js';

// 一口价专场
const fixedPriceApi = {
	// 获取数据列表
	getTable: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsItem/getTable',
		  params,
		  methods: 'post'
		})
	},
	// 获取筛选数据
	getSearchData: (params) => {
		return config.ajaxs({
		  url: 'api/Home/getSearchData',
		  params,
		  methods: 'post'
		})
	},
	// 获取筛选型号数据
	getGoodsListNew: (params) => {
		return config.ajaxs({
		  url: 'api/Home/getGoodsListNew',
		  params,
		  methods: 'post'
		})
	},
	// 获取商品详情数据
	getReadnew: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsItem/readnew',
		  params,
		  methods: 'post'
		})
	},
	// 一口价议价
	submitTalkPrice: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/submitTalkPrice',
		  params,
		  methods: 'post'
		})
	}
};

export default fixedPriceApi;