import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import QC from '../../../components/QC';
import * as api from "../../../api/index"; // 引入api
import { Table, Space, Button } from 'antd';

const OrderDetailTable = (props) => {
  const [info,setInfo] = useState({});
  const [address,setAddress] = useState({});
  const [modelShow,setModelShow] = useState(false);
  const [goodsId, setGoodsId] = useState();
  let obj_state = localStorage.getItem("orderDetailsTable_state_yppp")
	if (!props.location.status && obj_state) {
		props.location.status = JSON.parse(obj_state)
	}
  let { id, type } = props.location.status
  useEffect(()=>{
    localStorage.setItem("orderDetailsTable_state_yppp", JSON.stringify(props.location.status))
    if (type == 3) {
      getMyOrderDetaile()
    } else {
      getDetail()
    }
  },[])
  // 打开质检报告弹窗
  const goQC = (item) => {
    console.log(item,'item');
    setGoodsId(item.goods_item_id)
    setModelShow(true)
  };
  // 关闭质检报告弹窗
  const closeQC = () => {
    setModelShow(false)
  };
  // 获取订单详情
  const getDetail = () => {
    api.orderListApi.orderDetail({order_id: id}).then(res=>{
      if (res.code === 200) {
        setInfo(res.data)
        if (!res.data.link_city) {
          api.orderListApi.userAddress({}).then(res=>{
            if (res.code === 200) {
              res.data.forEach((item,index)=>{
                if (item.is_default == 2) {
                  setAddress(item)
                } else if (index === 0) {
                  setAddress(item)
                }
              })
            }
          })
        }
      }
    })
  };
  const getMyOrderDetaile = () => {
    api.orderListApi.getMyOrderDetaile({order_id:id}).then(res=>{
      if (res.code === 200) {
        setInfo(res.data)
        if (!res.data.link_city) {
          api.orderListApi.userAddress({}).then(res=>{
            if (res.code === 200) {
              res.data.forEach((item,index)=>{
                if (item.is_default == 2) {
                  setAddress(item)
                } else if (index === 0) {
                  setAddress(item)
                }
              })
            }
          })
        }
      }
    })
  };
  const dataSource = info?.goods_list || info?.order_goods
  let number = info?.goods_list?.length || info?.order_goods?.length
  const columns = [
    {
      title: '商品名称',
      dataIndex: 'goods_name',
      key: 'goods_name',
    },
    {
      title: '等级',
      dataIndex: 'grade_name',
      key: 'grade_name',
    },
    {
      title: 'SKU',
      dataIndex: 'sku_desc',
      key: 'sku_desc',
    },
    {
      title: '质检报告',
      dataIndex: '',
      key: '',
      render: (text, item) => (
        <Space size="middle">
          <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
        </Space>
      ),
    }
  ];
  const pageOption = {
    pageSize:5,
    total: number,
    showTotal: ()=>{
      return '共 '+number+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    },
  }
  // 表格分页跳转
  const pageChange = () => {
    console.log('分页跳转');
  };
  return (
    <div className={styles.order_detail_table}>
      <QC goodsId={goodsId} modelShow={modelShow} closeQC={closeQC} />
      <div className={styles.info}>
        <div className={styles.box}>收件人：<span>{info?.link_name || address?.name}</span></div>
        <div className={styles.box}>联系方式：<span>{info?.link_mobile || address?.mobile}</span></div>
        <div className={styles.box}>地址：<span>{(info?.link_province + info?.link_city + info?.link_area + info?.link_address || '') || (address?.province + address?.city + address?.area + address?.address || '')}</span></div>
        <div className={styles.box}>创建时间：<span>{info?.create_time}</span></div>
        <div className={styles.box}>订单编号：<span>{info?.sn}</span></div>
        <div className={styles.box}>场次：<span>{info?.activity_name}</span></div>
        {/* <div className={styles.box}>数量：<span>20111111</span></div>
        <div className={styles.box}>商品总额：<span>20111111</span></div>
        <div className={styles.box}>运费：<span>20111111</span></div>
        <div className={styles.box}>实付款：<span>20111111</span></div> */}
      </div>
      <Table className='table' rowKey={record=>record.goods_id} dataSource={dataSource} columns={columns} pagination={pageOption} />
    </div>
  )
};

export default OrderDetailTable