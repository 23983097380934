import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import QC from '../../../../../components/QC';

import { Link } from 'react-router-dom';
import { Select, Button, DatePicker, Space, Input, Table, Statistic, message } from 'antd';
const { Countdown } = Statistic;
const { Option } = Select;

const Content = (props) => {
  const [dateValue,setDateValue] = useState();
  const [classValue,setClassValue] = useState();
  const [stateValue,setStateValue] = useState();
  const [orderNumer,setOrderNumer] = useState();
  const [modelShow,setModelShow] = useState(false);
  const input = React.createRef()
  // 表格数据
  const dataSource = props.tableData;
  const columns = props.tabs == '1' ? 
  [
    {
      title: '竞价标题',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '竞价单类型',
      dataIndex: '',
      key: '',
      render: () => (
        <Space size="middle">
          <div>{props.tabs === '1' ? '暗拍专场' : '统拍专场'}</div>
        </Space>
      ),
    },
    {
      title: '截止时间（倒计时）',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <Countdown value={item.countdown_time ? Date.now() + (item.countdown_time || 0) * 1000 : Date.now() + (item.down_time || 0) * 1000} onFinish={()=>onFinish(item)} valueStyle={countdownStyle} />
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <span style={{color: '#169BD5', cursor: 'pointer'}} onClick={()=>goDetail(item)}>详情</span>
        </Space>
      ),
    }
  ] : [
    {
      title: '竞价标题',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '竞价单类型',
      dataIndex: '',
      key: '',
      render: () => (
        <Space size="middle">
          <div>{props.tabs === '1' ? '暗拍专场' : '统拍专场'}</div>
        </Space>
      ),
    },
    {
      title: '截止时间（倒计时）',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <Countdown value={item.countdown_time ? Date.now() + (item.countdown_time || 0) * 1000 : Date.now() + (item.down_time || 0) * 1000} onFinish={()=>onFinish(item)} valueStyle={countdownStyle} />
        </Space>
      ),
    },
    {
      title: '商品总数',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '起拍价',
      dataIndex: 'start_price',
      key: 'start_price',
      render: (start_price) => (
        <Space size="middle">
          <span>{start_price?`￥${start_price}`:null}</span>
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <span style={{color: '#169BD5', cursor: 'pointer'}} onClick={()=>goDetail(item)}>详情</span>
        </Space>
      ),
    }
  ] 
  // 跳转质检详情页
  const goDetail = (item) => {
    console.log(item,'itemitemitem');
    if (item.active_state == 0) {
      return message.warning(item.waiting_begin_desc)
    }
    if (item.active_state && item.active_state == 2) {
      return message.warning('已结束')
    }
    if (props.activeState == 1) {
      return message.warning('尚未开始')
    }
    if (props.activeState == 3) {
      return message.warning('已结束')
    }
    props.goDetail(item)
  };
  // 打开质检报告弹窗
  const goQC = () => {
    setModelShow(true)
  };
  // 关闭质检报告弹窗
  const closeQC = () => {
    setModelShow(false)
  };
  // 分页配置
  const pageOption = {
    pageSize: 5,
    total: props.tableData.length,
    showTotal: ()=>{
      return '共 '+props.tableData.length+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    }
  }
  // 创建起始时间筛选
  const dateChange = (date) => {
    console.log('修改日期',date);
    setDateValue(date)
  };
  // 竞价类型筛选
  const classChange = (value) => {
    setClassValue(value)
    console.log('选择的竞价类型' + value);
  };
  // 竞价状态筛选
  const stateChange = (value) => {
    setStateValue(value)
    console.log('选择的竞价状态等级' + value);
  };
  // 订单号输入
  const orderChange = (e) => {
    setOrderNumer(e.target.defaultValue)
  };
  // 重置筛选项
  const reset = () => {
    setDateValue()
    setClassValue()
    setStateValue()
    setOrderNumer('')
    input.current.state.value = ''
  };
  // 表格分页跳转
  const pageChange = () => {
    console.log('分页跳转');
  };
  // 倒计时结束
  const onFinish = (item) => {
    item.active_state ? item.active_state = 2 : item.state = 2
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)'
  }
  return (
    <div>
      <QC modelShow={modelShow} closeQC={closeQC} />
      <div className={styles.filter_box} style={{display: props.tabs === '3' ? 'flex' : 'none'}}>
        {/* 创建起始时间 */}
        <div className={styles.brand_box}>
          <div>创建起始时间：</div>
          <DatePicker value={dateValue} onChange={dateChange} />
        </div>
        {/* 竞价类型 */}
        <div className={styles.brand_box}>
          <div>竞价类型：</div>
          <Select value={classValue} placeholder='请选择' style={{ width: 130 }} onChange={classChange}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
        </div>
        {/* 竞价状态 */}
        <div className={styles.brand_box}>
          <div>竞价状态：</div>
          <Select value={stateValue} placeholder='请选择' style={{ width: 130 }} onChange={stateChange}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
        </div>
        {/* 竞价单号 */}
        <div className={styles.brand_box}>
          <div>竞价单号：</div>
          <Input ref={input} placeholder="请输入单号" style={{width: '200px'}} onChange={orderChange} />
        </div>
        <div className={styles.btns}>
          <Button type="primary" style={{marginRight: '16px'}}>查询</Button>
          <Button onClick={reset}>重置</Button>
        </div>
      </div>
      <Table className='table' rowKey={record=>record.id} dataSource={dataSource} columns={columns} pagination={pageOption} />
    </div>
  )
};

export default Content