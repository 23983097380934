import config from './config.js';

// 购物车
const shoppingCartApi = {
	// 加入购物车
	addCart: (params) => {
		return config.ajaxs({
		  url: 'api/Cart/store',
		  params,
		  methods: 'post'
		})
	},
    // 购物车列表
	cartList: (params) => {
		return config.ajaxs({
		  url: 'api/Cart/index',
		  params,
		  methods: 'post'
		})
	},
    // 删除购物车数据
	deleteCartGoods: (params) => {
		return config.ajaxs({
		  url: 'api/Cart/delete',
		  params,
		  methods: 'post'
		})
	}
};

export default shoppingCartApi;