import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import { Select, Button, Table, Space } from 'antd';
const { Option } = Select;

const History = (props) => {
  const [classValue,setClassValue] = useState();
  const [gradeValue,setGradeValue] = useState();
  const [page,setPage] = useState(1);
  // 品类筛选
  const classChange = (value) => {
    setClassValue(value)
    console.log('选择的品类' + value);
  };
  // 等级筛选
  const gradeChange = (value) => {
    setGradeValue(value)
    console.log('选择的等级' + value);
  };
  // 查询筛选项
  const search = (isReset) => {
    setPage(1)
    console.log(isReset,'isReset');
    if (isReset) {
      props.getHistoryList()
    } else {
      props.getHistoryList(classValue,gradeValue)
    }
  };
  // 重置筛选项
  const reset = () => {
    setClassValue()
    setGradeValue()
    search(true)
  };
  const dataSource = props.historyList
  const columns = [
    {
      title: '竞价标题',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '竞价单类型',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Space size="middle">
          <div>{type == '1' ? '暗拍专场' : '统拍专场'}</div>
        </Space>
      ),
    },
    {
      title: '结束时间',
      dataIndex: 'e_time',
      key: 'e_time',
    },
    {
      title: '本场出价台数',
      dataIndex: 'total_number',
      key: 'total_number',
    },
    {
      title: '我的出价',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total_price) => (
        <Space size="middle">
          <span>{total_price?`￥${total_price}`:null}</span>
        </Space>
      ),
    },
    {
      title: '状态',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <span style={{color: item.is_success == '1' ? '#08CC7E' : '#FF3232',display: item.type == '1' ? 'none' : 'block'}}>{item.is_success == '1' ? '成功' : '失败'}</span>
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <Space size="middle">
          <span style={{color: '#169BD5', cursor: 'pointer'}} onClick={()=>goDetail(item)}>详情</span>
        </Space>
      ),
    }
  ];
  // 跳转详情页
  const goDetail = (item) => {
    props.goDetail(item)
  };
  // 表格分页跳转
  const pageChange = (current) => {
    setPage(current)
    console.log('分页跳转');
  };
  // 分页配置
  const pageOption = {
    current:page,
    pageSize:5,
    total:props.historyList.length,
    showTotal: ()=>{
      return '共 '+props.historyList.length+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    }
  }
  return (
    <div className={styles.history}>
      <div className={styles.head_box}>
        <div className={styles.filter_box}>
          {/* 所属品类 */}
          <div className={styles.brand_box}>
            <div>时间：</div>
            <Select value={classValue} placeholder='请选择时间' style={{ width: 130 }} onChange={classChange}>
              {
                props.historyFilter && props.historyFilter.time? props.historyFilter.time.map(item=>{
                  return (
                    <Option value={item.key} key={item.key}>{item.value}</Option>
                  )
                }) : null
              }
            </Select>
          </div>
          {/* 等级 */}
          <div className={styles.brand_box}>
            <div>类型：</div>
            <Select value={gradeValue} placeholder='请选择类型' style={{ width: 130 }} onChange={gradeChange}>
              {
                props.historyFilter && props.historyFilter.type ? props.historyFilter.type.map(item=>{
                  return (
                    <Option value={item.key} key={item.key}>{item.value}</Option>
                  )
                }) : null
              }
            </Select>
          </div>
          <div className={styles.btns}>
            <Button onClick={()=>search()} type="primary" style={{marginRight: '16px'}}>查询</Button>
            <Button onClick={reset}>重置</Button>
          </div>
        </div> 
      </div>
      <Table className='table' loading={props.tableLoading} dataSource={dataSource} columns={columns} pagination={pageOption}></Table>
    </div>
  )
};

export default History