import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index.js"; // 引入api
import { RightOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import HeardNav from './components/heardNav';
import Footer from '../../home/components/footer';
import avatarSmall from '../../../static/emptyIcon.png'

const Article = (props) => {
  const access_token = localStorage.getItem("access_token") // token
  const [info, setInfo] = useState({})
  const [num, setNum] = useState(0)
  const [down, setDown] = useState(true)
  const [id, setId] = useState()
  const [cateList, setCateList] = useState([])
  const [total, setTotal] = useState(0)
  const [pages, setPages] = useState(1)

  // 进入页面加载
  useEffect(()=>{
    // 获取热门资讯
    getHotNews()
  },[num])

  // 获取热门资讯
  const getHotNews = () => {
    api.homeApi.articleIndex({limit: 10, page: 1}).then(res=>{
      if (res.code === 200) {
        setInfo(res.data)
        setId(res.data.cate[0].id)
        getNewsList(res.data.cate[0].id, 1)
        setTotal(res.data.cate[0].count)
      }
    })
  }

  // 获取资讯列表
  const getNewsList = (cate_id, pageNumber) => {
    api.homeApi.cateArticle({limit: 10, page: pageNumber, cate_id: cate_id}).then(res=>{
      if (res.code === 200) {
        setCateList(res.data)
      }
    })
  }

  const hotLiChange = (item) => {
    setId(item.id)
    setTotal(item.count)
    getNewsList(item.id, 1)
    window.scrollTo(0, 0);
  }

    // 分页切换
  const onChange = (pageNumber) => {
    setPages(pageNumber)
    getNewsList(id, pageNumber)
    window.scrollTo(0, 0);
  }

  // 进入资讯详情
  const joinNews = (item) => {
    props.history.push({pathname: '/news', status: {id: item.id}})
  }

  // 头部登录，竞拍，买家中心
  const goLoginBidding = (index) => {
    if (!access_token) {
	    props.history.push('/login')
      return false
    }
    if (index == 1 || index == 2) {
        props.history.push({pathname: '/backStage/orderList', status: {key: '31', menu: '3', tabs: 'WAIT_PAY'}})
    } else if (index == 0) {
        props.history.push({pathname: '/backStage/userCenter', status: {key: '11', menu: '1'}})
    } else {
	    props.history.push('/login')
    }
  };

  const goBackHome = () => {
    props.history.push('/home')
  }

  // 热门资讯
  const hotNews = () => {
    setDown(!down)
  }
  
  return (
    <div>
      {/* 头部导航区 */}
      {
       <HeardNav goBackHome={goBackHome} goLoginBidding={goLoginBidding} />
      }

      {/* 资讯列表 */}
      <div className={styles.wrap_list}>
            <div className={styles.news_list}>
                <div className={styles.breadcrumb}>
                    <span className={styles.cursor} onClick={goBackHome}>首页</span><span><RightOutlined className={styles.light} /></span><span className={styles.cursor}>热门资讯</span>
                </div>
            </div>

            <div className={styles.newsC}>
                {/* 热门资讯 */}
                <div className={styles.navigation}>
                    <div className={styles.hotLeft} onClick={hotNews}>
                        <div className={styles.hotList}>
                            <span style={{color: down ? '#FF3232' : '', fontWeight: down ? '600' : '400'}}>热门资讯</span>
                            <i className={[styles.elSubmenuIconArrow, down ? styles.elIconArrowDown:''].join(' ')}></i>
                        </div>
                    </div>
                    <div className={styles.hotListBox} style={{display: down ? 'block':'none'}}>
                        {
                            info && info.cate ? info.cate.map((item, index) => {
                                return (
                                   <div key={index} className={styles.hotLli} style={{color: id == item.id ? '#FF3232':''}} onClick={hotLiChange.bind(this, item)}>{item.cate_name}</div>
                                )
                            }): null
                        }
                    </div>
                </div>
                <div className={styles.newsCRight}>
                    {
                        cateList.length !== 0 && cateList ? cateList.map((item, index) => {
                            return (
                                <div className={styles.hotli} key={index} onClick={joinNews.bind(this, item)}>
                                    <div className={styles.goitem}>
                                        {
                                            item.cover_imgs[0] ? (<img className={styles.hotImg} src={item.cover_imgs[0]}></img>):(<img className={styles.hotImg} src={avatarSmall}></img>)
                                        }
                                        <div className={styles.hotlitext}>
                                            <div className={styles.title}>{item.title}</div>
                                            <div className={styles.introduction}>{item.introduction}</div>
                                            <div className={styles.time}>
                                                <span>{item.read_count}阅读</span>
                                                <span>{item.create_time}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null
                    }
                    
                    <div className={styles.pageination}>
                        {
                            total ? (<Pagination defaultCurrent={1} total={total} onChange={onChange} />):null
                        }
                        
                    </div>
                </div>
            </div>
      </div>

      {/* 底部 */}
      <Footer />
    </div>
  )
};

export default Article;
