import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index.js"; // 引入api
import { message } from 'antd';

const UserCenter = (props) => {
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const [addressList, setAddressList] = useState({})
  // 进入页面加载
  useEffect(()=>{
    console.log('3232')
    // 地址列表
    api.loginApi.userAddress().then((res) => {
      if (res.code === 200) {
        if (res.data.length > 0) {
          setAddressList(res.data[0])
        }
      } else {
        message.info(res.msg);
        if (res.code == '401') {
          props.history.push('/login')
        }
      }
    })
  },[0])
  return (
    <div>
      <div className={styles.information}>
        <div className={styles.material}>个人资料</div>
        <div className={styles.number}>手机号码  {access_user && access_user.mobile ? (React.$axios.hide(access_user.mobile)):null}</div>
      </div>

      <div className={styles.shipping_address}>
        <div className={styles.my_address}><span>我的收货地址</span></div>
        <div className={styles.methods}>
          <div>收件地区：{addressList.province} {addressList.city} {addressList.area}</div>
          <div>收货人：{addressList.name}</div>
          <div>详细地址：{addressList.address}</div>
          <div>手机号码：{addressList.mobile}</div>
        </div>
      </div>
    </div>
  )
};

export default UserCenter;
