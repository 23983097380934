/**
 * 数字的隐藏 保留前3位和后3位
 */
export function hide(params) {
  if (params) {
    return params.replace(/^(\d{3})[\d|\s]*(\d{3})$/, '$1*****$2')
  } else {
    return ''
  }
}

/**
 * 数字的隐藏 保留后2位
 */
export function iphoneHide(params) {
  if (params) {
    return params.replace(/^(\d{0})[\d|\s]*(\d{2})$/, '$1*****$2')
  } else {
    return ''
  }
}