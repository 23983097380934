import config from './config.js';

// 我的议价
const negotiatedPriceApi = {
	// 获取列表数据
  getBargainList: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/getBargainList',
		  params,
		  methods: 'post'
		})
	},
	// 获取历史列表数据
	getHistory: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/history',
		  params,
		  methods: 'post'
		})
	},
	// 获取筛选数据
  getClassData: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/getClassData',
		  params,
		  methods: 'post'
		})
	},
	// 获取型号
  getGoodsTypes: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/goodsTypes',
		  params,
		  methods: 'post'
		})
	},
	// 取消议价
  cancelPrice: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/cancelPrice',
		  params,
		  methods: 'post'
		})
	},
	// 改价时获取记录数据
  getPersonalRecord: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/getPersonalRecord',
		  params,
		  methods: 'post'
		})
	},
	// 提交改价
	savePrice: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/savePrice',
		  params,
		  methods: 'post'
		})
	},
	// 获取记录数据
	getRecord: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsTalkPrice/getRecord',
		  params,
		  methods: 'post'
		})
	},
};
export default negotiatedPriceApi;