import config from './config.js';

const homeApi = {
	// 首页数据
    homeIndex: (params) => {
		return config.ajaxs({
		  url: 'api/Home/homeIndex',
		  params,
		  methods: 'post'
		})
	},
	// 首页资讯
    article: (params) => {
		return config.ajaxs({
		  url: 'api/Article/index',
		  params,
		  methods: 'post'
		})
	},
	// 资讯详情
    articleInfo: (params) => {
		return config.ajaxs({
		  url: 'api/Article/articleInfo',
		  params,
		  methods: 'post'
		})
	},
	// 热门资讯列表
    articleIndex: (params) => {
		return config.ajaxs({
		  url: 'api/Article/index',
		  params,
		  methods: 'post'
		})
	},
	// 热门新闻列表
    cateArticle: (params) => {
		return config.ajaxs({
		  url: 'api/Article/cateArticle',
		  params,
		  methods: 'post'
		})
	},
    // 首页一口价专场数据
    getTable: (params) => {
		return config.ajaxs({
		  url: 'api/GoodsItem/getTable',
		  params,
		  methods: 'post'
		})
	},
    // 我的订单
    orderCategory: (params) => {
		return config.ajaxs({
		  url: 'api/User/orderCategory',
		  params,
		  methods: 'post'
		})
	},
};
export default homeApi;