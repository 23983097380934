import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

import UserCenter from '../page/userCenter';
import BiddingSession from '../page/biddingSession';
import OrderDetails from '../page/orderDetails/index.js';
import FixedPrice from '../page/fixedPrice';
import OrderList from '../page/orderList';
import BiddingSessionDetail from '../page/biddingSessionDetail';
import AfterSale from '../page/afterSale';
import NegotiatedPrice from '../page/negotiatedPrice';
import OrderDetailTable from '../page/orderDetailTable';
import BiddingPrice from '../page/biddingPrice';

import { Link } from 'react-router-dom';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import siderTitleData from '../../router/routerData.json';

import { UserSwitchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Layout, Menu, Popover, Button, Breadcrumb } from 'antd';


const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const BackStage = (props) => {
  const [num,setNum] = useState(0); // 保证useEffect执行一次
  const [crumbs,setCrumbs] = useState(['个人中心','个人中心'])
  const rootSubmenuKeys = ['个人中心','竞价管理','订单管理']
  const [openKeys, setOpenKeys] = useState(['2']); // 默认展开菜单
  const [selectedKeys, setSelectedKeys] = useState(['21']); // 默认选中菜单
  const [tabs, setTabs] = useState('2')
  const breadcrumbNameMap = { // 面包屑映射
    '11':['个人中心','个人中心'],
    '21':['竞价管理','竞价场次列表'],
    '22':['竞价管理','一口价列表'],
	  '24':['订单详情'],
    '31':['订单管理','订单列表'],
    '32':['订单管理','退货/售后'],
    '33':['订单管理','我的议价'],
  };
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const path = props.history.location.pathname
  let pathBool
  if (path === '/backStage/orderDetailTable' || path === '/backStage/biddingSessiont/detail') {
    pathBool = true
  } else {
    pathBool = false
  }
  // 进入页面跳转个人中心页
  useEffect(()=>{
    console.log(localStorage.getItem('menu_key_yppp'))
    let menu_key_yppp = JSON.parse(localStorage.getItem('menu_key_yppp'))
    console.log(menu_key_yppp)
    let key = props.location.status?.key || menu_key_yppp?.key
    let menu = props.location.status?.menu || menu_key_yppp?.menu
    console.log(key,menu)
    for (const e in breadcrumbNameMap) {
      if (e == key) {
        setCrumbs(breadcrumbNameMap[key])
      }
    }
    let obj = {
      key,
      menu
    }
    localStorage.setItem('menu_key_yppp', JSON.stringify(obj))
    setSelectedKeys([key])
    setOpenKeys([menu])
  },[num])

  // 侧边栏展开收起菜单
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // 点击菜单, 存储menu
  const clickMenu = (item, items) => {
    let obj = {
      key: String(items.id),
      menu: String(item.id)
    }
    localStorage.setItem('menu_key_yppp', JSON.stringify(obj))
  }

  // 侧边栏选中菜单
  const onSelect = e => {
    // 点击等级说明是不需要高亮显示
    if (e.key === '23') return false
    for (const key in breadcrumbNameMap) {
      if (key === e.key) {
        setCrumbs(breadcrumbNameMap[key])
      }
    }
    setSelectedKeys(e.selectedKeys)
  };
  // 返回首页
  const backHome = () => {
    props.history.push('/home')
  }
  // 退出登录
  const outLogins = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_user');
    props.history.push('/login')
  };

  // 退出登录气泡框
  const outLogin = (
    <Button type="text" onClick={outLogins}>{access_user ? '退出登录' : '登录'}</Button>
  );
  // 返回上一页
  const goBack = () => {
    let {pathname} = props.history.location
    if (pathname === '/backStage/orderDetailTable') {
      let {tabs} = props.history.location.status
      props.history.push({pathname: '/backStage/orderList', status: {tabs}})
    } else if (pathname === '/backStage/biddingSessiont/detail') {
      let {types} = props.history.location.state
      props.history.push({pathname: '/backStage/biddingSession', status: {tabs:types}})
    }
    // props.history.goBack()
  };

  return (
    <div className={styles.back_stage}>
      <Layout className={styles.layout}>
        {/* 左侧导航区 */}
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
        >
          <div className={styles.logo} onClick={backHome}>优品拍拍</div>
          <Menu
            mode="inline"
            theme="dark"
            openKeys={openKeys} 
            selectedKeys={selectedKeys}
            onOpenChange={onOpenChange}
            onSelect={onSelect}
          >
            {siderTitleData.data.map(item=>{
              return (
                <SubMenu key={item.id} title={item.sub} >
                  {item.children.map(items=>{
                    if (items.option && items.option !== '等级说明') {
                      return  <Menu.Item key={items.id} onClick={clickMenu.bind(this, item, items)}>
                                <Link to={`/${items.pageName}`} replace>
                                  <span className="nav-text">{items.option}</span>
                                </Link>
                              </Menu.Item>;
                    } else if (items.option && items.option === '等级说明') {
                      return  <Menu.Item key={items.id}>
                                <a href={items.pageName} target="_blank" className="nav-text" rel="noreferrer">{items.option}</a>
                              </Menu.Item>;
                    }
                  })}
                </SubMenu>
              );
            })}
          </Menu>
          
          {/* <div className={styles.service}>
            <img src="https://resources.huanhuanhuishou.com/new_image/20210706/icon_kefu_white.png" />
            <div>在线客服</div>
          </div> */}
        </Sider>
        {/* 右侧内容区 */}
        <Layout>
          <Header style={{ padding: 0,background: '#fff',height: 50,lineHeight: '50px'}}>
            <div className={styles.user_info}>
              <div className={styles.name}>
                {
                  access_user && access_user.mobile ? (<span>{access_user.mobile}</span>) : (<span>未登录</span>)      
                }
                <Popover content={outLogin} trigger="hover">
                  <UserSwitchOutlined />
                </Popover>
              </div>
            </div>
          </Header>
          <Content style={{ margin: '6px 16px 0 16px',overflowY: 'scroll' }}>
            <Breadcrumb className={styles.bread_crumd}>
              <div className={styles.go_back} style={{display: pathBool ? 'block' : 'none'}} onClick={goBack}><ArrowLeftOutlined style={{fontSize: '20px'}} /></div>
              {crumbs.map((item,index) => {
                return (
                  <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
            <div className="" style={{ padding: '20px 0px', minHeight: 360 }}>
              <Switch>
                <Route exact path="/backStage/userCenter" component={UserCenter} ></Route>
                <Route exact path="/backStage/biddingSession" tabs={tabs} component={BiddingSession} ></Route>
                <Route exact path="/backStage/biddingPrice" component={BiddingPrice} ></Route>
				        <Route exact path="/backStage/orderDetails" component={OrderDetails} ></Route>
                <Route exact path="/backStage/fixedPrice" component={FixedPrice} ></Route>
                <Route exact path="/backStage/biddingSessiont/detail" component={BiddingSessionDetail} ></Route>
                <Route exact path="/backStage/orderList" tabs={tabs} component={OrderList} ></Route>
                <Route exact path="/backStage/afterSale" component={AfterSale} ></Route>
                <Route exact path="/backStage/negotiatedPrice" component={NegotiatedPrice} ></Route>
                <Route exact path="/backStage/orderDetailTable" component={OrderDetailTable} ></Route>
              </Switch>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
};

export default BackStage