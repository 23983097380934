import React, { useState, useEffect } from 'react';
import styles from './index.module.scss'
import { Input, Statistic, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as api from "../../api/index.js"; // 引入api
const { Countdown } = Statistic;

const Login = (props) => {
  const [isCode,setIsCode] = useState(false); // 验证码60倒计时控制
  const [mobile,setMobile] = useState(''); // 手机号码
  const [code,setCode] = useState(''); // 验证码
  // 验证码倒计时60s
  let deadline = Date.now() + 60000;
  // 倒计时样式
  const countdownStyle = {
    fontSize: '14px',
    fontFamily: 'PingFang SC',
    fontWeight: '400',
    color: '#666666',
    width: '40px',
    textAlign: 'right'
  }
   // 检查手机号
    const checkPhone = () => {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(mobile);
    };
    // 检查验证码
    const checkCode = () => {
      // return /^[a-zA-Z]\w{3}$/.test(initWordValue);
      return code.length === 4;
    };

  // 验证码倒计时结束
  const onFinish = () => {
    setIsCode(false)
  };
  // 获取验证码
  const getCode = () => {
    if (!checkPhone()) return message.info('请输入正确的手机号码哦');
    let data = {
      mobile: mobile,
      scene: 'login'
    }
    api.loginApi.sendPhoneCode(data).then((res) => {
      if (res.code == 200) {
        message.info('发送成功');
        setIsCode(true)
        deadline = Date.now() + 60000
      } else {
        message.info(res.msg);
      }
	  })
    
  };
  //判断点击的键盘的keyCode是否为13，是就调用上面的搜索函数
  const handleEnterKey = (e) => {
    if (e.nativeEvent.keyCode == 13) {
      login()
    }
  };
  // 登录
  const login = () => {
	if (!checkPhone()) return message.info('请输入正确的手机号码哦');
	if (!checkCode()) return message.info('请输入正确的验证码哦');
	let data = {
		mobile,
		verify_code: code
	}
	api.loginApi.login(data).then((res) => {
		if (res.code == 200) {
			message.info('登录成功');
      localStorage.setItem('access_token', res.data.access_token) // token
      localStorage.setItem('access_user', JSON.stringify(res.data.user)) // 账户信息
			props.history.push('/home')
		} else {
      message.info(res.msg);
    }
	})

  };

  return (
    <div className={styles.login}>
      <div className={styles.box}></div>
      <div className={styles.login_box}>
        <img src='https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_logos.png' />
        <div className={styles.title}>优品拍拍</div>
        <div className={styles.input_box}>
          <Input placeholder="请输入手机号码" bordered={false} maxLength={11} onChange={(e)=>setMobile(e.target.value)} />
          <UserOutlined />
        </div>
        <div className={styles.input_box}>
          <Input placeholder="请输入验证码" bordered={false} maxLength={4} onKeyPress={handleEnterKey} onChange={(e)=>setCode(e.target.value)} />
          <div className={styles.code}>
            <Countdown style={isCode ? {display:'block'} : {display:'none'}} valueStyle={countdownStyle} value={deadline} format="s秒" onFinish={onFinish} />
            <div style={isCode ? {display:'none'} : {display:'block'}} className={styles.text} onClick={getCode}>获取验证码</div>
          </div>
        </div>
        <div className={styles.btn} onClick={login}>登录</div>
      </div>
    </div>
  )
};

export default Login