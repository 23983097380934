import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

import * as api from "../../../../../api/index"; // 引入api

import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Button, DatePicker, Input, Table, Statistic, Modal, message, Select, Popconfirm } from 'antd';
const { Countdown } = Statistic;
const { Option } = Select;

const Content = (props) => {
  // const [dateValue,setDateValue] = useState();
  // const [orderNumer,setOrderNumer] = useState();
  const [pageSize,setPageSize] = useState(5);
  const [totalPrice,setTotalPrice] = useState(0); // 合计金额
  const [total,setTotal] = useState(0); // 选中商品
  const [modelShow,setModelShow] = useState(false);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [alterShow, setAlterShow] = useState(false); // 弹出框
  const [TPOrderNumber, setTPOrderNumber] = useState([]); // 统拍物流单号
  const [isTP, setIsTP] = useState(false); // 是否为统拍
  const [selectNumber, setSelectNumber] = useState(); // 选中的物流单号
  const [logisticsInfo, setLogisticsInfo] = useState({express_number:'',express_name:'',now_time:''}); // 物流信息
  
  // const input = React.createRef()
  // const inputs = React.createRef()
  // 表格数据
  const dataSource = props.orderList
  const columns = props.tabs === 'WAIT_PAY' ? [
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
    },
    {
      title: '订单编号',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: '订单类型',
      dataIndex: 'type_text',
      key: 'type_text',
    },
    {
      title: '商品数量',
      dataIndex: 'total_num',
      key: 'total_num',
    },
    {
      title: '应付金额（含交易服务费）',
      dataIndex: 'total_price',
      key: 'total_price',
    },
    {
      title: '支付剩余时间',
      dataIndex: 'down_time',
      key: 'down_time',
      render: (down_time) => (
        <Space size="middle">
          <Countdown value={ Date.now() + (down_time || 0) * 1000 } onFinish={onFinish} valueStyle={countdownStyle} />
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <div size="middle" style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <span style={{color: '#169BD5', cursor: 'pointer'}}  onClick={settlement}>去支付</span>
          <span onClick={()=>goDetail(item)} style={{color: '#169BD5', cursor: 'pointer'}}>详情</span>
        </div>
      ),
    },
  ] : [
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
    },
    {
      title: '订单编号',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: '订单类型',
      dataIndex: 'type_text',
      key: 'type_text',
    },
    {
      title: '商品数量',
      dataIndex: 'total_num',
      key: 'total_num',
    },
    {
      title: '应付金额（含交易服务费）',
      dataIndex: 'total_price',
      key: 'total_price',
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (item) => (
        <div size="middle" style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <span style={{color: '#169BD5', cursor: 'pointer', display: props.tabs === 'WAIT_PAY' ? 'block' : 'none'}} onClick={settlement}>去支付</span>
          <span onClick={()=>openAlter('isLogistics',item)} style={{color: '#169BD5', cursor: 'pointer', display: props.tabs === 'DISPATCHED' || props.tabs === 'FINISHED' ? 'block' : 'none'}}>查看物流</span>
          <Popconfirm title="请确认是否收货?" onConfirm={() => confirmReceipt(item.id)}>
            <span style={{color: '#169BD5', cursor: 'pointer', display: props.tabs === 'DISPATCHED' ? 'block' : 'none'}}>确认收货</span>
          </Popconfirm>
          <span onClick={afterSale} style={{color: '#169BD5', cursor: 'pointer', display: props.tabs === 'FINISHED' ? 'block' : 'none'}}>售后详情</span>
          <span onClick={()=>goDetail(item)} style={{color: '#169BD5', cursor: 'pointer'}}>详情</span>
        </div>
      ),
    },
  ];
  const goDetail = (item) => {
    props.goDetail(item)
  };
  // 分页配置
  const pageOption = {
    pageSize:pageSize,
    total: props.orderList.length,
    pageSizeOptions:[5,10],
    showTotal: ()=>{
      return '共 '+props.orderList.length+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    },
    onShowSizeChange: (current,size) => {
      pageSizeChange(current,size)
    },
  }
  const pageSizeChange = (current,size) => {
    console.log(current,size,'current,size');
    setPageSize(size)
  }
  // 打开弹出框
  const openAlter = (type,item) => {
    console.log(type,item);
    if (type === 'isLogistics') {
      if (item.type_text === '统拍') {
        setIsTP(true)
        setTPOrderNumber(item.express_number)
      } else {
        api.orderListApi.orderLogistics({order_id: item.id, express_number: ''}).then(res=>{
          if (res.code === 200) {
            console.log(moment(parseInt(res.data.now_time + '000')).format('YYYY-MM-DD HH:mm:ss'));
            res.data.now_time = moment(Number(res.data.now_time + '000')).format('YYYY-MM-DD HH:mm:ss')
            setLogisticsInfo(res.data)
          }
        })
      }
    }
    setAlterShow(true)
  };
  // 弹出框确认
  const alterOk = () => {};
  // 弹出框关闭
  const alterCancel = () => {
    setAlterShow(false)
    setSelectNumber()
    setLogisticsInfo({express_number:'',express_name:'',now_time:''})
  };
  // 选中物流单号
  const numberChange = (value) => {
    setSelectNumber(value);
    api.orderListApi.orderLogistics({order_id: '', express_number: value}).then(res=>{
      if (res.code === 200) {
        res.data.now_time = moment(Number(res.data.now_time + '000')).format('YYYY-MM-DD HH:mm:ss')
        setLogisticsInfo(res.data)
      }
    })
  };
  // 确认收货
  const confirmReceipt = (id) => {
    props.setTableLoding(true)
    api.orderListApi.confirmOrder({order_id:id}).then(res=>{
      if (res.code === 200) {
        message.success('确认收货成功');
        props.getOrderList(props.tabs)
      } else {
        message.error(res.msg);
      }
      props.setTableLoding(false)
    })
  };
  // 售后提示
  const afterSale = () => {
    message.warning('目前退货/售后单功能已全部迁移至优品拍拍APP,请下载后操作');
  }
  // 表格分页跳转
  const pageChange = () => {
    console.log('分页跳转');
  };
  // 倒计时结束
  const onFinish = () => {
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)'
  }
  // 结算
  const settlement = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '请到APP付款',
      okText: '确认',
      cancelText: '取消',
    });
  }
  // 多选
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let number = 0;
      selectedRows.map(item=>{
        return number += Number(item.total_price)
      })
      setTotal(selectedRows.length)
      setTotalPrice(number)
      console.log(number,'合计');
    },
    getCheckboxProps: (record) => ({
      disabled: record.button !== 1,
    }),
  };

  return (
    <div>
      <Table className='table' loading={props.tableLoding} rowKey={record=>record.id} dataSource={dataSource} columns={columns} rowSelection={{type: selectionType,...rowSelection}} pagination={pageOption} />
      <div className={styles.footer} style={{display: props.tabs === 'WAIT_PAY' ? 'flex' : 'none'}}>
        <div className={styles.box}>已选商品<span style={{color:'#F80303'}}> {total} </span>件</div>
        <div className={styles.box}>合计（不含运费）：<span style={{color:'#F80303'}}>{totalPrice}</span></div>
        <div className={styles.btn} onClick={settlement}>结算</div>
      </div>
      <Modal wrapClassName={styles.alter} className={styles.modal} visible={alterShow} footer={null} onOk={alterOk} onCancel={alterCancel}>
        <div className={styles.brand_box} style={{display: isTP ? 'flex' : 'none'}}>
          <div style={{lineHeight: '31px'}}>物流单号：</div>
          <Select value={selectNumber} placeholder='请选择物流单号' style={{ width: 160 }} onChange={numberChange}>
            {
              TPOrderNumber && TPOrderNumber.length !== 0 ? TPOrderNumber.map((item,index) => {
                return (
                  <Option key={index} value={item}>{item}</Option>
                )
              }) : null
            }
          </Select>
        </div>
        <div className={styles.Logistics}>
          <div style={{display: isTP ? 'none' : 'block'}}>物流单号：{logisticsInfo.express_number}</div>
          <div>快递：{logisticsInfo.express_name}</div>
          <div>预计送达时间：{logisticsInfo.now_time}</div>
        </div>
      </Modal>      
    </div>
  )
};

export default Content