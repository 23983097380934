import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../../api/index"; // 引入api
import avatarSmall from '../../../../static/emptyIcon.png'
import {
  DoubleRightOutlined
} from '@ant-design/icons';

const Picker = (props) => {
  const [num, setNum] = useState(0)
  const [leftData, setLeftData] = useState([])
  const [rightData, setRightData] = useState([])
  useEffect(()=>{
    getArticle()
  },[num])
  
  // 获取资讯数据
  const getArticle = () => {
    api.homeApi.article({page:1,limit:6}).then(res=>{
      if (res.code == 200) {
         let data = res.data.article
         setLeftData(data.splice(0, 2))
         let newData = data.splice(0, 4)
         setRightData(newData)
      }
    })
  }

  // 资讯详情
	// 模板中调用joinDetails方法，参数是item对象
  const joinDetails = (item) => {
    props.joinDetails(item) //props 获取父组件上属性集合对象
  }

  // 资讯列表
  const goArticle = () => {
    props.goArticle()
  }

  const ranking_icon = [
	  'https://resources.huanhuanhuishou.com/global_source/CA8C329F-7E27-09A9-8044-3B2E5014BFD0.png',
	  'https://resources.huanhuanhuishou.com/global_source/8D04B849-80B3-816A-5F53-5D28EB782457.png',
	  'https://resources.huanhuanhuishou.com/global_source/152706AE-350F-F672-229C-C3F1A0972ABF.png'
  ]

  return (
    <div className={styles.picker}>
      <div className={styles.title}>新闻资讯</div>
      <div className={styles.tag}>手机、平板、笔记本、智能数码、摄影摄像应有尽有</div>
      <div className={styles.content}>
        <div className={styles.left_video}>
          {
            leftData.map((item) => {
              return (
                <div className={styles.news_view} onClick={joinDetails.bind(this, item)}>
                  <div className={styles.wrap_image}>
                    {
                      item.cover_imgs[0] ? (<img src={item.cover_imgs[0]} className={styles.news_cover_picture}></img>):(<img src={avatarSmall} className={styles.news_cover_picture}></img>)
                    }
                  </div>
                  <div className={styles.news_titles}>{item.title}</div>
                  <div className={styles.news_content}>{item.introduction}</div>
                </div>
              )
            })
          }
        </div>
        
        <div className={styles.bottom_news}>
          <div className={styles.news_title}>
            <div className={styles.name}>热门资讯</div>
          <div className={styles.more} onClick={goArticle}>
              <span>更多</span>
              <span className={styles.right_icon}><DoubleRightOutlined /></span>
          </div>
          </div>
          {rightData.map((item,index)=>{
            return (
            <div className={styles.news_list} key={index} onClick={joinDetails.bind(this, item)}>
              <div className={styles.left_flex}>
                {
                  item.cover_imgs[0] ? (<img className={styles.goods_img} src={item.cover_imgs[0]} />):(<img className={styles.goods_img} src={avatarSmall} />)
                }
                <img style={{display:index < 3 ? '':'none'}} className={styles.ranking_img} src={ranking_icon[index]} />
              </div>
              <div className={styles.right_news}>
                <div className={styles.list_title}>{item.title}</div>
                <div className={styles.list_text}>{item.introduction}</div>
              </div>
            </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default Picker