import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index"; // 引入api

import Content from './components/content';
import { Tabs, message } from 'antd';
const { TabPane } = Tabs;


const NegotiatedPrice = (props) => {
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const [num,setNum] = useState(0);
  const [page,setPage] = useState(1);
  const [tabs,setTabs] = useState(0);
  const [list,setList] = useState([]);
  const [filterData,setFilterData] = useState();
  const [gradeData,setGradeData] = useState([]);
  const [tableLoding, setTableLoding] = useState(false);
  // 标签页切换
  const callback = (key) => {
    setTableLoding(true)
    setTabs(key)
    console.log(key=== '1,2');
    key === '1,2' ? getHistoryData(key,'','') : getData(key,'','')
    getFilterData(key)
  };

  useEffect(()=>{
    if (!access_user) {
      message.info('未登录');
      props.history.push('/login')
      return false
    }
    getFilterData(0)
    getData(tabs,'','')
  },[num]);
  // 获取列表数据
  const getData = (state,goods_id,grade) => {
    let params = {
      limit: 99999999,
      page,
      state,
      goods_id,
      grade
    }
    api.negotiatedPriceApi.getBargainList(params).then(res=>{
      if (res.code === 200) {
        setTableLoding(false)
        if (filterData && filterData.search) {
          let list = []
          res.data.forEach(item=>{
            filterData.search.quality.forEach(i=>{
              if (item.grade == i.key || i.key == 0) {
                list.push(i)
              }
            })
          })
          setGradeData(list)
        }
        setList(res.data)
      }
    })
  };
  // 获取历史列表数据
  const getHistoryData = (state,goods_id,grade) => {
    let params = {
      limit: 99999999,
      page,
      state,
      goods_id,
      grade
    }
    api.negotiatedPriceApi.getHistory(params).then(res=>{
      if (res.code === 200) {
        setTableLoding(false)
        setList(res.data)
      }
    })
  };
  // 获取筛选条件
  const getFilterData = (key) => {
    api.negotiatedPriceApi.getClassData({state:key}).then(res=>{
      console.log(res);
      if (res.code === 200) {
        setGradeData(res.data.search.quality)
        setFilterData(res.data)
      }
    })
  };
  // 确认搜索
  const search = (goods_id,grade) => {
    setTableLoding(true)
    tabs === '1,2' ? getHistoryData(tabs,goods_id,grade) : getData(tabs,goods_id,grade)
  };

  return (
    <div className={styles.negotiated_price}>
      {/* 标签页 */}
      <Tabs defaultActiveKey="0" onChange={callback}>
        <TabPane tab="议价中" key="0">
          <Content list={list} filterData={filterData} tabs={tabs} tableLoding={tableLoding} gradeData={gradeData} search={search} setTableLoding={setTableLoding} />
        </TabPane>
        <TabPane tab="议价成功" key="1">
          <Content list={list} filterData={filterData} tabs={tabs} tableLoding={tableLoding} gradeData={gradeData} search={search} setTableLoding={setTableLoding} />
        </TabPane>
        <TabPane tab="历史议价" key="1,2">
          <Content list={list} filterData={filterData} tabs={tabs} tableLoding={tableLoding} gradeData={gradeData} search={search} setTableLoding={setTableLoding} />
        </TabPane>
      </Tabs>
    </div>
  )
};

export default NegotiatedPrice