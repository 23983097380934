import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Popover, Carousel } from 'antd';

const HeardNav = (props) => {
  const access_token = localStorage.getItem("access_token") // token
  const access_user = JSON.parse(localStorage.getItem("access_user")) // 账户信息
  const [carouselData,setCarouselData] = useState()
  const [orderCategory,setOrderCategory] = useState()
   // 顶部功能区数据
  const topFunction = ['登录注册','我的竞拍','买家中心'];
  const [topList, setTopList] = useState(topFunction)
  useEffect(()=>{
    let list = props.banner
    setCarouselData(list)
    setOrderCategory(props.orderCategory)
    setOrderData()
    if (access_token) {
      topFunction[0] = React.$axios.iphoneHide(access_user.mobile)
      setTopList(topFunction)
    }
  },[props.orderCategory])
  // 手机版二维码
  const phoneCode = (
    <div>
      <img style={{width:'200px',height:'200px'}} src="https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_xiazai.png"></img>
    </div>
  );
  // 活动场次提示文案
  const activeText = [
    {
      text:'暗拍专场',
      time:'19:00 - 次日凌晨04:00'
    },
    // {
    //   text:'暗拍下午场',
    //   time:'12:00 - 18:00'
    // },
    // {
    //   text:'暗拍晚上场',
    //   time:'19:00 - 次日02:00'
    // }
  ];
  // 订单类型数据
  const orderList = [
    {
      text:'竞品中',
      value:0
    },
    {
      text:'待支付',
      value:0
    },
    {
      text:'待收货',
      value:0
    },
    // {
    //   text:'退货/售后',
    //   value:0
    // },
  ];
  const setOrderData = () => {
 
  }
  // 公告数据
  const announcementData = [
    // {
    //   text:'买家售后规则规范更新 >>',
    //   url:'https://webview-test.hhyp58.com/paijitang/aftersale/flow2/?title=%E5%B9%B3%E5%8F%B0%E8%A7%84%E5%88%99'
    // },
    {
      text:'优品拍拍等级说明查看 >>',
      url:'https://mp.weixin.qq.com/s/wKd1YKwxHbc7Qy5jlcx1iQ'
    },
  ];
  // 调取父组件方法跳转后台页面
  const goBackStage = (item) => {
    props.goBackStage(item)
  };
  // 调取父组件方法跳转后台页面
  const goLoginBidding = (index) => {
	  props.goLoginBidding(index)
  };

  return (
    <div className={styles.heard_nav}>
      {/* 顶部功能区 */}
      <div className={styles.function}>
        <div className={styles.box}>
          {topList.map((item,index)=>{
            return (
              <div className={styles.item} key={index} onClick={goLoginBidding.bind(this, index)}>
                <div className={styles.text}>{item}</div>
                <div className={styles.br}></div>
              </div>
            )
          })}
          <div className={styles.item}>
            <Popover content={phoneCode} style={{display:'flex'}}>
              <div className={styles.text}>手机版</div>
            </Popover>
          </div>
        </div>
      </div>
      {/* logo tab区 */}
      <div className={styles.logo_tab}>
        <img src="https://resources.huanhuanhuishou.com/new_image/20210812/hhyp_pc_logo.png" />
        <div className={styles.active_box}>
          {activeText && activeText.length !==0 ? activeText.map((item,index)=>{
            return (
              <div className={styles.item} key={index}>
                <div className={styles.text}>{item.text}</div>
                <div className={styles.text} style={{fontSize:'14px'}}>{item.time}</div>
              </div>
            )
          }):null}
        </div>
      </div>
      {/* 轮播区 */}
      <div className={styles.carousel}>
        {/* <Carousel effect="fade" autoplay>
          {carouselData && carouselData.length !== 0 ? carouselData.map((item,index)=>{
            return (
              <div className={styles.carousel_box} style={{ background: `url('${item.coverage}') center center /cover` }} key={index}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  <img src={item.img_url}></img>
                </a>
              </div>
            )
          }):null}
        </Carousel> */}
        <div className={styles.info_show}>
          <div className={styles.login_box}>
            <div className={styles.name}>Hi，欢迎来到优品拍拍</div>
            {
              access_token?(<div className={styles.order}>
                {orderCategory && orderCategory.length !== 0 ? orderCategory.map((item,index)=>{
                  return (
                    <div className={styles.item} key={index} onClick={goBackStage.bind(this,item)}>
                      <div className={styles.number}>{item.count}</div>
                      <div className={styles.text}>{item.name}</div>
                    </div>
                  )
                }):null}
              </div>):(<div className={styles.btn} onClick={goLoginBidding}>注册/登录</div>)
            }  
          </div>
          <div className={styles.announcement_box}>
            <div className={styles.title}>公告</div>
            <div className={styles.list}>
              {announcementData.map((item,index)=>{
                return (
                  <div className={styles.item} key={index}>

                    <a href={item.url} target="_blank" rel="noreferrer">
                      <div className={styles.tag}></div>
                      <div className={styles.text}>{item.text}</div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {/* 流程图区 */}
      <img className={styles.flow} src="https://resources.huanhuanhuishou.com/new_image/20210812/hhyp_pc_liuchen.png" />
    </div>
  )
};

export default HeardNav