import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { Provider } from 'mobx-react';
import store from'./store';
import reportWebVitals from './reportWebVitals';
// ant中文汉化
import zh_CN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import { ConfigProvider } from 'antd';
import * as filters from './common/filter/index' // 全局filter

// 全局注册filter
React.$axios = filters

ReactDOM.render(
  <Router>
    <Provider {...store}>
      <ConfigProvider locale={zh_CN}><App /></ConfigProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
