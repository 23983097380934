import { observable, makeObservable, action } from 'mobx';

// 常量改成类
class LoginStore {
  constructor() {
    makeObservable(this); // 添加makeObservable 监听数据的改变渲染视图
  }
  // 登录白名单
  @observable accountRoll= [
    { userName: '17620384592', passWord: '1234' },
    { userName: '17322028282', passWord: '123456' }
  ]
  // 新增用户
  @action addAccountRoll (params) {
    this.accountRoll.push(params);
  }
}

export default LoginStore;