import config from './config.js';

// 订单列表
const orderListApi = {
  // 获取订单列表
  getTable: (params) => {
		return config.ajaxs({
		  url: 'api/Order/getTable',
		  params,
		  methods: 'post'
		})
	},
  // 获取订单物流信息
  orderLogistics: (params) => {
		return config.ajaxs({
		  url: 'api/Order/orderLogistics',
		  params,
		  methods: 'post'
		})
	},
  // 确认收货
  confirmOrder: (params) => {
		return config.ajaxs({
		  url: 'api/Order/confirmOrder',
		  params,
		  methods: 'post'
		})
	},
	// 获取订单详情
	orderDetail: (params) => {
		return config.ajaxs({
		  url: 'api/Order/orderDetail',
		  params,
		  methods: 'post'
		})
	},
	// 获取地址信息
	userAddress: (params) => {
		return config.ajaxs({
		  url: 'api/userAddress/index',
		  params,
		  methods: 'post'
		})
	},
	// 获取统拍订单详情
	getMyOrderDetaile: (params) => {
		return config.ajaxs({
		  url: 'api/Order/getMyOrderDetaile',
		  params,
		  methods: 'post'
		})
	},
};

export default orderListApi;
