import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Modal, message, Checkbox } from "antd";
import * as api from "../../../api/index"; // 引入api
import { values } from "mobx";

const BiddingPrice = (props) => {
  const [cartFoodsList, setCartFoodsList] = useState([]);
  const [isSelected, setIsSelected] = useState(false); // 是否选中商品
  const [selectedRow, setSelectedRow] = useState([]); // 选中商品id
  const [selectedPrice, setSelectedPrice] = useState(0); // 选中商品总价格
  const [tableLoding,setTableLoding] = useState(true);
  useEffect(()=>{
    getCartData()
  },[])
  // 分页配置
  const [paginationCurrent, setPaginationCurrent] = useState(1)
  const pageOption = {
    pageSize: 8,
    total: cartFoodsList.length,
    showTotal: () => {
      return "共 " + cartFoodsList.length + " 条";
    },
    onChange: (current, pageSize) => {
      pageChange(current, pageSize);
      setPaginationCurrent(current)
    },
  };
  // 获取购物车列表
  const getCartData = () => {
    let params = {
      page: 1,
      size: 999999
    }
    api.shoppingCartApi.cartList(params).then(res=>{
      if (res.code == 200) {
        setCartFoodsList(res.data.reverse())
        setTableLoding(false)
      } else {
        message.info(res.msg)
        setTableLoding(false)
      }
    })
  };
  // table 选中
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      console.log(selectedRowKeys)
      setSelectedRow(selectedRowKeys)
      if (selectedRowKeys.length) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
      
      var totalPrice = 0
      cartFoodsList.forEach(item => {
        selectedRowKeys.forEach(e => {
          if (item.id == e) {
            totalPrice += Number(item.amount)
          }
        })
      })
      setSelectedPrice(totalPrice.toFixed())
    },
    // columnTitle: () => {
    //   return <div><Checkbox>全选</Checkbox></div>
    // },
    getCheckboxProps: (record) => ({
      disabled: record.state === 2
    }),
  };
  // 表格分页跳转
  const pageChange = () => {
    console.log("分页跳转");
  };
  //删除购物车数据
  const deleteGoods = (record, index) => {
    console.log(record, index)
    Modal.confirm({
			centered: true,
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '确定删除该商品吗?',
			okText: '确认',
			cancelText: '取消',
			onOk() {
				let params = {
					cart_ids: record.id
				}
        let idx = (paginationCurrent - 1) * 5 + index
			  api.shoppingCartApi.deleteCartGoods(params).then((res) => {
					if (res.code === 200) {
            let list = cartFoodsList.concat()
            list.splice(idx, 1)
            setCartFoodsList(list)
            let selected = selectedRow.concat()
            selected.forEach((item, i) => {
              if (item == record.id) {
                selected.splice(i, 1)
              }
            })
            setSelectedRow(selected)
            var totalPrice = 0
            console.log(list)
            console.log(selected)
            list.forEach(item => {
              selected.forEach(e => {
                if (item.id == e) {
                  totalPrice += Number(item.amount)
                }
              })
            })
            setSelectedPrice(totalPrice.toFixed())
						message.info('删除成功!')
					} else {
						message.info(res.msg);
					}
				})
			},
		})
  };
  // 清除失效商品
  const clearInvalid = () => {
    Modal.confirm({
			centered: true,
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '确定清除失效商品吗?',
			okText: '确认',
			cancelText: '取消',
			onOk() {
        const ids = []
        cartFoodsList.forEach(item => {
          // 过滤失效商品
          if (item.state == 2) {
            ids.push(item.id)
          }
        })
        
        if (ids.length == 0) {
          message.info('暂无失效商品!')
        } else {
          const cart_ids = ids.join(',')
          let params = {
            cart_ids
          }
          api.shoppingCartApi.deleteCartGoods(params).then((res) => {
            if (res.code === 200) {
              message.info('删除成功!')
              setTableLoding(true)
              getCartData()
            } else {
              message.info(res.msg);
            }
          })
        }

			},
		})
  };
  // 订单结算
  const orderSettlement = () => {
    if (!isSelected) return false
    let list = []
    cartFoodsList.forEach(e => {
      selectedRow.forEach(item => {
        if (e.id == item) {
          list.push(e)
        }
      })
    })
    props.history.push({pathname: '/backStage/orderDetails', state: {data: list, totalAmount: selectedPrice}})
  };
  // 监听表格多选
  const selectOnchange = (e) => {
    console.log(e)
    // console.log(`checked = ${e.target.checked}`);
    // console.log(selectedRowKeys);
  };


  const columns = [
    {
      title: "商品信息",
      dataIndex: "goods_name",
      key: "goods_name",
      render: (text, value) => {
        return (
          <div>{value.goods_name}&nbsp;&nbsp;{value.sku_desc}</div>
        )
      },
    },
    {
      title: "等级",
      dataIndex: "grade",
      key: "grade",
    },
    // {
    //   title: "单价",
    //   dataIndex: "amount",
    //   key: "amount",
    //   render: (text) => {
    //     let amount = Number(text).toFixed()
    //     return (
    //       <div style={{ color: "#FF3232" }}>{amount}</div>
    //     )
    //   },
    // },
    {
      title: "数量",
      dataIndex: "count",
      key: "count",
      render: () => <div>1</div>,
    },
    {
      title: "金额",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        let amount = Number(text).toFixed()
        return (
          <div style={{ color: "#FF3232" }}>{amount}</div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (state) => <div>{state == 2?'失效':'正常'}</div>,
    },
    {
      title: "操作",
      render: (text, record, index) => {
        return (
          <div onClick={deleteGoods.bind(this, record, index)} style={{ color: "#1E9FFF", cursor: 'pointer'}}>删除</div>
        );
      },
    },
  ];

  return (
    <div className={styles.bidding_price}>
      <Table
        loading={tableLoding}
        className='table'
        rowKey={cartFoodsList => cartFoodsList.id}
        dataSource={cartFoodsList}
        columns={columns}
        rowSelection={rowSelection}
        onChange={selectOnchange}
        pagination={pageOption}
      />
      <div className={styles.all_select}>全选</div>
      <div className={styles.all_option}>
        <div className={styles.left}>
          <span className={styles.clear} onClick={clearInvalid}>清除失效宝贝</span>
        </div>
        <div className={styles.right}>
          <div className={styles.select_goods}>
            已选商品件 <span className={styles.num}>{selectedRow.length}</span>件
          </div>
          <span className={styles.total}>合计(不含运费):</span>
          <span className={styles.price}>{selectedPrice}</span>
          <div className={isSelected?styles.settlement:styles.settlement_none} onClick={orderSettlement}>结算</div>
        </div>
      </div>
    </div>
  );
};

export default BiddingPrice;
