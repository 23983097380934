import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import * as api from "../../../api/index"; // 引入api

import { Select, Button, Table, Space, Statistic, Modal, Radio, message, Form  } from 'antd';
import GoPriceModal from './components/goPriceModal';
import QC from '../../../components/QC';
const { Option } = Select;
const { Countdown } = Statistic;

const BiddingSessionDetail = (props) => {
  const [classValue,setClassValue] = useState();
  const [brandValue,setBrandValue] = useState();
  const [modelValue,setModelValue] = useState();
  const [gradeValue,setGradeValue] = useState();
  const [modelShow,setModelShow] = useState(false);
  const [type,setType] = useState('1');
  const [title,setTitle] = useState('');
  const [goPrice,setGoPrice] = useState('0');
  const [time,setTime] = useState(0);
  const [page,setPage] = useState(1);
  const [id,setID] = useState();
  const [goodsList,setGoodsList] = useState([]);
  const [filterData,setFilterData] = useState({});
  const [gradeList,setGradeList] = useState([]);
  const [brandList,setBrandList] = useState([]);
  const [goodsLists,setGoodsLists] = useState([]);
  const [attrList,setAttrList] = useState([]);
  const [tableLoading,setTableLoading] = useState(false);
  const [btnLoading,setbtnLoading] = useState(false);
  const [form] = Form.useForm();
  const [propertyShow,setPropertyShow] = useState(false);
  const [modalShow,setModalShow] = useState(false)
  const [selectItem,setSelectItem] = useState({})
  const [goodsId, setGoodsId] = useState();
  const [goodsActivityId,setGoodsActivityId] = useState();
  const [TPInfo,setTpInfo] = useState({name:'', total:'', start_price:'', reference_price:'',})
  const childRef = useRef();
  
  let obj_state = localStorage.getItem("location_state_yppp")
  if (obj_state && !props.location.state) {
    props.location.state = JSON.parse(obj_state)
  }
  let tabs = props.location.state.types
  let kind = props.location.state.kinds
  
  useEffect(()=>{
    localStorage.setItem("location_state_yppp", JSON.stringify(props.location.state))
    if (props.location.state) {
      let { id, types, states, titles } = props.location.state
      setType(types)
      setTitle(titles)
      setID(id)
      console.log(kind,'kind');
      if (types === '1' || kind == '1') {
        console.log(111);
        getAPFilterData(id)
      }
      if (types === '2' || kind == '2') {
        getTPFilterData(id)
        getDetailData(id)
      }
      getGoodsListData(id)
    }
  },[])
  // 获取详情信息
  const getDetailData = (id) => {
    api.biddingSessionApi.getDetails({id}).then(res=>{
      console.log(res);
      if (res.code === 200) {
        setTpInfo(res.data)
        setTime(Date.now() + res.data.count_down * 1000)
      }
    })
  };
  // 获取商品列表
  const getGoodsListData = (id,class_id,goods_id,grade,attr,isSearch) => {
    setTableLoading(true)
    let params ={
      page: 1,
      limit: 9999999999999999,
      query: {
        goods_id,
        grade,
        sort: '',
        min_price: '',
        max_price: '',
        class_id,
        activity_id: id || '',
        attr
      }
    }
    if (tabs === '1') {
      api.biddingSessionApi.getGoodsLists(params).then(res=>{
        if (res.code === 200) {
          setPage(1)
          setGoodsList(res.data.list)
          setTableLoading(false)
          if (isSearch) {
            api.biddingSessionApi.getGradeByGoods({goods_id,type:1}).then(res=>{
              if (res.code === 200) {
                setGradeList(res.data)
              }
            })
          }
        }
      })
    } else if (tabs === '2') {
      api.biddingSessionApi.getGoodsList(params).then(res=>{
        console.log(res);
        if (res.code === 200) {
          setPage(1)
          setGoPrice(res.data.total_price)
          setGoodsList(res.data.list)
          setTableLoading(false)
        }
      })
    };
    let data = {
      page: 1,
      limit: 9999999999999999,
      class_id:class_id || '',
      goods_id:goods_id || '',
      id,
      grade:grade || ''
    }
    if (kind == '1') {
      api.biddingSessionApi.getPriceOfferList(data).then(res=>{
        if (res.code === 200) {
          setPage(1)
          setGoodsList(res.data)
          setTableLoading(false)
        }
      })
    } else if (kind == '2') {
      api.biddingSessionApi.getUnifiedList(data).then(res=>{
        if (res.code === 200) {
          setPage(1)
          setGoodsList(res.data)
          setTableLoading(false)
        }
      })
    }
  };
  // 获取暗拍筛选数据
  const getAPFilterData = (id) => {
    let params
    if (kind) {
      params = {activity_id:id || '',is_user_center: 1}
    } else {
      params = {activity_id:id || ''}
    }
    api.biddingSessionApi.getClassData(params).then(res=>{
      console.log(res);
      if (res.code === 200) {
        setTime(Date.now() + res.data.countdown_time * 1000)
        console.log(res.data.search.quality,'res.data.search.quality');
        setGradeList(res.data.search.quality)
        setFilterData(res.data)
      }
    })
  };
  // 获取统拍筛选数据
  const getTPFilterData = (id) => {
    let params
    if (kind) {
      params = {activity_id:id || '',is_user_center: 1}
    } else {
      params = {activity_id:id || ''}
    }
    api.biddingSessionApi.getClassDatas(params).then(res=>{
      if (res.code === 200) {
        setFilterData(res.data)
        setGradeList(res.data.search.quality)
      }
    })
  };
  // 表格数据
  const dataSource = goodsList
  let columns
  if (tabs === '1') {
    columns = [
      {
        title: 'SKU信息',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle" style={{display:'block'}}>
            <div>{item.goods_name}</div>
            <div>{item.sku_desc}</div>
          </Space>
        ),
      },
      {
        title: '等级',
        dataIndex: 'grade_text',
        key: 'grade_text',
      },
      {
        title: '质检报告',
        dataIndex: '',
        key: '',
        render: (text, item) => (
          <Space size="middle">
            <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
          </Space>
        ),
      },
      {
        title: '起拍价',
        dataIndex: 'price',
        key: 'price',
        render: (price) => (
          <Space size="middle">
            <span>￥{price}</span>
          </Space>
        ),
      },
      {
        title: '库存量',
        dataIndex: 'item_count',
        key: 'item_count',
      },
      {
        title: '操作',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle">
            {/* <span onClick={goOrderDetail} style={{color: '#169BD5', cursor: 'pointer'}}>去付款</span> */}
            <span onClick={()=>openGoPrice(item)} style={{color: '#169BD5', cursor: 'pointer', display: tabs === '2' ? 'none' : 'block'}}>出价</span>
          </Space>
        ),
      },
    ];
  } else if (tabs === '2') {
    columns = [
      {
        title: 'SKU信息',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle" style={{display:'block'}}>
            <div>{item.goods_name}</div>
            <div>{item.sku_desc}</div>
          </Space>
        ),
      },
      {
        title: '等级',
        dataIndex: 'grade_text',
        key: 'grade_text',
      },
      {
        title: '质检报告',
        dataIndex: '',
        key: '',
        render: (text, item) => (
          <Space size="middle">
            <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
          </Space>
        ),
      },
      {
        title: '库存量',
        dataIndex: 'item_count',
        key: 'item_count',
      },
      {
        title: '操作',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle">
            {/* <span onClick={goOrderDetail} style={{color: '#169BD5', cursor: 'pointer'}}>去付款</span> */}
            <span onClick={()=>openGoPrice(item)} style={{color: '#169BD5', cursor: 'pointer', display: tabs === '2' ? 'none' : 'block'}}>出价</span>
          </Space>
        ),
      },
    ];
  } else if (tabs == '4' && kind == '1') {
    columns = [
      {
        title: 'SKU信息',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle" style={{display:'block'}}>
            <div>{item.goods_name}</div>
            <div>{item.sku_desc}</div>
          </Space>
        ),
      },
      {
        title: '等级',
        dataIndex: 'grade_text',
        key: 'grade_text',
      },
      {
        title: '质检报告',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle">
            <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
          </Space>
        ),
      },
      {
        title: '我的出价',
        dataIndex: 'max_price',
        key: 'max_price',
        render: (max_price) => (
          <Space size="middle">
            <span>￥{max_price}</span>
          </Space>
        ),
      },
      {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        render: (state) => (
          <Space size="middle">
            <span style={{color: state == '1' ? '#08CC7E' : '#FF3232'}}>{state == '1' ? '成功' : '失败'}</span>
          </Space>
        ),
      },
    ];
  }
  else if (kind == '1') {
    columns = [
      {
        title: 'SKU信息',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle" style={{display:'block'}}>
            <div>{item.goods_name}</div>
            <div>{item.sku_desc}</div>
          </Space>
        ),
      },
      {
        title: '等级',
        dataIndex: 'grade_text',
        key: 'grade_text',
      },
      {
        title: '质检报告',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle">
            <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
          </Space>
        ),
      },
      {
        title: '我的出价',
        dataIndex: 'max_price',
        key: 'max_price',
        render: (max_price) => (
          <Space size="middle">
            <span>￥{max_price}</span>
          </Space>
        ),
      },
      {
        title: '操作',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle">
            <span onClick={()=>openGoPrice(item)} style={{color: '#169BD5', cursor: 'pointer', display: kind === '2' || tabs == '4' ? 'none' : 'block'}}>改价</span>
          </Space>
        ),
      },
    ];
  } else if (kind == '2') {
    columns = [
      {
        title: 'SKU信息',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle" style={{display:'block'}}>
            <div>{item.goods_name}</div>
            <div>{item.sku_desc}</div>
          </Space>
        ),
      },
      {
        title: '等级',
        dataIndex: 'grade_text',
        key: 'grade_text',
      },
      {
        title: '质检报告',
        dataIndex: '',
        key: '',
        render: (item) => (
          <Space size="middle">
            <Button type="primary" onClick={goQC.bind(this, item)}>质检报告</Button>
          </Space>
        ),
      }
    ];
  }
  
  // 打开出价弹窗
  const openGoPrice = (item) => {
    setModalShow(true)
    if (tabs === '1' || kind == '1') {
      setSelectItem(item)
    }
  };
  // 暗拍出价
  const APSave = (price) => {
    console.log(selectItem,'selectItem');
    let params = {
      report_item_id: selectItem.goods_item_id,
      price,
      activity_id: selectItem.activity_id,
      number: 1
    }
    api.biddingSessionApi.save(params).then(res=>{
      console.log(res);
      if (res.code === 200) {
        message.success('出价成功')
        childRef.current.reset()
        setModalShow(false)
        if (kind == 1) {
          let data = {
            page: 1,
            limit: 9999999999999999,
            class_id:classValue || '',
            goods_id:modelValue || '',
            id,
            grade:gradeValue || ''
          }
          api.biddingSessionApi.getPriceOfferList(data).then(res=>{
            if (res.code === 200) {
              setPage(1)
              setGoodsList(res.data)
              setTableLoading(false)
            }
          })
        }
        console.log(kind,11);
        // search()
      } else {
        message.error(res.msg)
      }
    })
  }
  // 统拍出价
  const offerPrices = (price) => {
    api.biddingSessionApi.offerPrice({activity_id: TPInfo.id, price, num: TPInfo.total}).then(res=>{
      if (res.code === 200) {
        message.success('出价成功')
        getDetailData(id)
        childRef.current.reset()
        setModalShow(false)
      } else {
        message.error(res.msg)
      }
    })
  };
  // 跳转订单详情付款页
  const goOrderDetail = () => {
    props.history.push('/backStage/orderDetails')
  };
  // 打开质检报告弹窗
  const goQC = (item) => {
    console.log(item)
    console.log(type)
    // if (type == 1) {
      // 暗拍
      setGoodsId(item.goods_item_id)
      if (item.activity_id) {
        setGoodsActivityId(item.activity_id)
      }

    // } else if (type == 2) {
      // 统拍
      // setGoodsId(item.goods_item_id)
    // }
    
    setModelShow(true)
  };
  // 关闭质检报告弹窗
  const closeQC = () => {
    setModelShow(false)
  };
  // 分页配置
  const pageOption = {
    current:page,
    pageSize:20,
    total:goodsList.length,
    showTotal: ()=>{
      return '共 '+goodsList.length+' 条'
    },
    onChange: (current,pageSize) => {
      pageChange(current,pageSize)
    }
  }
  // 品牌筛选
  const brandChange = (value) => {
    setBrandValue(value)
    setGradeValue()
    setModelValue()
    let params
    if (kind) {
      params = {brand_id: value, class_id: classValue, activity_id: id,is_user_center: 1}
    } else {
      params = {brand_id: value, class_id: classValue, activity_id: id}
    }
    if (tabs === '1' || kind == '1') {
      api.biddingSessionApi.goodsTypes(params).then(res=>{
        console.log(res);
        if (res.code === 200) {
          setGoodsLists(res.data)
        }
      })
    } else if (tabs === '2' || kind == '2') {
      api.biddingSessionApi.goodsTypess(params).then(res=>{
        console.log(res);
        if (res.code === 200) {
          setGoodsLists(res.data)
        }
      })
    }
    
    console.log('选择的品牌' + value);
  };
  // 品类筛选
  const classChange = (value) => {
    setClassValue(value)
    setBrandValue()
    setGradeValue()
    setModelValue()
    filterData.search.model.map(item=>{
      if (item.id === value) {
        setBrandList(item.list)
      }
    })
    console.log('选择的品类' + value);
  };
  // 型号筛选
  const modelChange = (value) => {
    setModelValue(value)
    setGradeValue()
    if (value != 0) {
      setbtnLoading(true)
      api.biddingSessionApi.getGoodsAttr({goods_id:value}).then(res=>{
        if (res.code === 200) {
          if (res.data.attr && res.data.attr.length !== 0) {
            setAttrList(res.data.attr[0].list)
            setTimeout(() => {
              setbtnLoading(false)
            }, 1000);
          }
        }
      })
    }
  };
  // 等级筛选
  const gradeChange = (value) => {
    setGradeValue(value)
    console.log('选择的等级' + value);
  };
  // 查询筛选项
  const search = () => {
    if (!brandValue && brandValue !== 0) {
      return message.warning('请先选择品牌后进行筛选')
    }
    let selectGoodsId = ''
    if (modelValue && modelValue != 0) {
      selectGoodsId = modelValue
    } else {
      if (brandValue) {
        let arr = []
        goodsLists.forEach(item=>{
          arr.push(item.goods_id)
        })
        console.log(arr,'arr');
        selectGoodsId = arr.join(',')
      }
    }
    getGoodsListData(id,classValue,selectGoodsId,gradeValue,attrData.join(','),true)
  };
  // 重置筛选项
  const reset = () => {
    selectAttr = []
    setBrandValue()
    setClassValue()
    setGradeValue()
    setModelValue()
    getGoodsListData(id)
  };
  // 打开属性筛选弹窗
  const openProperty = () => {
    if (!modelValue || modelValue == 0) {
      return message.warning('请先选择一个型号')
    }
    setPropertyShow(true)
  };
  let selectAttr = []
  let attrData = []
  // 属性弹窗确认筛选
  const countermandOk = () => {
    selectAttr.forEach(item=>{
      attrData.push(item.id)
    })
    search()
    setPropertyShow(false)
  };
  // 属性弹窗重置
  const propertyReset = () => {
    setAttrList([])
    setTimeout(() => {
      setAttrList(attrList)
    }, );
    // form.resetFields(); // 表单清空
    selectAttr = []
    console.log(selectAttr,'selectAttr');
  };
  // 属性弹窗关闭
  const countermandCancel = () => {
    setPropertyShow(false)
  };
  // 属性弹窗选择
  const radioChange = (e) => {
    console.log(e.target.value);
    if (selectAttr.length === 0) {
      selectAttr.push({pid: e.target.value.pid,id: e.target.value.id})
    } else {
      for (let i = 0; i < selectAttr.length; i++) {
        if (selectAttr[i].pid === e.target.value.pid) {
          selectAttr[i].id = e.target.value.id
          return
        }
      }
      selectAttr.push({pid: e.target.value.pid,id: e.target.value.id})
    }
  };
  // 表格分页跳转
  const pageChange = (current,pageSize) => {
    setPage(current)
    console.log('分页跳转');
  };
  // 倒计时结束
  const onFinish = () => {
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '18px',
    color: '#fff',
    fontWeight: 'bold',
  }

  return (
    <div className={styles.detail}>
      <QC activityId={goodsActivityId} goodsId={goodsId} modelShow={modelShow} closeQC={closeQC} />
      {/* 顶部信息展示 */}
      <div className={styles.info_box}>
        <div className={styles.left}>
          <div className={styles.tag}>{type==='1' || kind == '1'?'暗拍':'统拍'}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.time}>
            <Countdown value={time} onFinish={onFinish} valueStyle={countdownStyle} />
            <div className={styles.txt}>结束</div>
          </div>
          <a className={styles.text} target="_blank" rel="noreferrer" href='https://mp.weixin.qq.com/s/wKd1YKwxHbc7Qy5jlcx1iQ'>成色说明</a>
        </div>
        <div className={styles.right}>
          {/* 暗拍展示信息，区分 结束：（竞价单号/直接竞得总数量/竞得总金额/卖家确认出售总数量）未结束：（竞价单号/已报价/出价总额） */}
          {/* <div className={styles.anpai} style={{display: type==='1'?'flex':'none'}}>
          </div> */}
          {/* 统拍展示信息，区分结束与未结束 */}
          {/* 结束:(竞价单号/竞品名称/创建时间/身份编号/物品总数/最终竞得价/我的报价) */}
          <div className={styles.tongpai} style={{display: type==='2' || kind=='2'?'flex':'none'}}>
            <div className={styles.info}>
              <div className={styles.box}>
                <div className={styles.text}>场次名称：</div>
                <div className={styles.value}>{TPInfo.name ? TPInfo.name : ''}</div>
              </div>
              <div className={styles.box}>
                <div className={styles.text}>物品总数：</div>
                <div className={styles.value}>{TPInfo.total || 0}</div>
              </div>
              <div className={styles.box}>
                <div className={styles.text}>起拍价：</div>
                <div className={styles.value}>￥{TPInfo.start_price || ''}</div>
              </div>
              <div className={styles.box}>
                <div className={styles.text}>参考价：</div>
                <div className={styles.value}>￥{TPInfo.reference_price || ''}</div>
              </div>
              <div className={styles.box} style={{display: kind=='2'?'flex':'none'}}>
                <div className={styles.text}>我的出价：</div>
                <div className={styles.value}>￥{TPInfo.current_price || ''}</div>
              </div>
              {/* <div className={styles.box}>
                <div className={styles.text}>我的报价：</div>
                <div className={styles.value_box}>
                  <div className={styles.value}>￥0.00</div>
                  <div className={styles.tag}>另需收取交易服务费</div>
                </div>
              </div> */}
              <Button onClick={openGoPrice} type="primary" style={{marginLeft: '50px',display: (kind=='2' && tabs !== '4') || tabs != '4' ?'flex':'none'}}>出价</Button>
            </div>
            {/* <Button type="primary" style={{marginLeft: '50px'}}>出价</Button> */}
            
          </div>
        </div>
      </div>
      {/* 头部筛选 */}
      <div className={styles.head_box}>
        <div className={styles.filter_box}>
          {/* 所属品类 */}
          <div className={styles.brand_box}>
            <div>品类：</div>
            <Select value={classValue} placeholder='请选择品类' style={{ width: 130 }} onChange={classChange}>
              {
                filterData && filterData.cate_list ? filterData.cate_list.map(item=>{
                  if (item.id != 0) {
                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                  }
                }) : null
              }
            </Select>
          </div>
          {/* 所属品牌 */}
          <div className={styles.brand_box}>
            <div>品牌：</div>
            <Select value={brandValue} placeholder='请选择品牌' style={{ width: 130 }} onChange={brandChange}>
              {
                brandList && brandList.length !== 0 ? brandList.map(item=>{
                  return <Option value={item.brand_id} key={item.brand_id}>{item.brand_name}</Option>
                }) : null
              }
            </Select>
          </div>
          {/* 所属型号 */}
          <div className={styles.brand_box}>
            <div>型号：</div>
            <Select value={modelValue} placeholder='请选择型号' style={{ width: 190 }} onChange={modelChange}>
              {
                goodsLists && goodsLists.length !== 0 ? goodsLists.map(item=>{
                  return <Option value={item.goods_id} key={item.goods_id}>{item.goods_name}</Option>
                }) : null
              }
            </Select>
          </div>
          {/* 等级 */}
          <div className={styles.brand_box}>
            <div>等级：</div>
            <Select value={gradeValue} placeholder='请选择等级' style={{ width: 130 }} onChange={gradeChange}>
              {
                gradeList && gradeList.length !==0 ? gradeList.map((item,index)=>{
                  return <Option value={item.key} key={index}>{item.value}</Option>
                }) : null
              }
            </Select>
          </div>
          <div className={styles.brand_box} style={{width: '160px'}}>
            <Button onClick={openProperty} type="primary" style={{width: '140px',marginRight: '16px', display: tabs !== '1' ? 'none' : 'block'}} loading={btnLoading}>属性筛选</Button>
          </div>
          <div className={styles.btns}>
            <Button onClick={search} type="primary" style={{marginRight: '16px'}}>查询</Button>
            <Button onClick={reset}>重置</Button>
          </div>
        </div>
      </div>
      {/* 表格展示 */}
      <div className={styles.table_box}>
        <Table className='table' scroll={{ x: 1500, y: 400 }} rowKey={record=>record.id} loading={tableLoading} dataSource={dataSource} columns={columns} pagination={pageOption} />
      </div>
      {/* 属性筛选弹窗 */}
      <Modal wrapClassName={styles.property} className={styles.modal} width={800} visible={propertyShow} footer={null} onOk={countermandOk} onCancel={countermandCancel}>
        <div style={{maxHeight: '500px', overflowY: 'scroll', marginTop: '30px', padding: '0 2px'}}>
          {
            attrList && attrList.length !== 0 ? attrList.map(item=>{
              return (
                <div className={styles.item_box} key={item.id}>
                  <div className={styles.title} style={{fontWeight: 'bold', fontSize: '16px', marginBottom: '10px'}}>{item.name}</div>
                  <div className={styles.item}>
                        <Radio.Group onChange={radioChange}>
                          {
                            item.list.map(item=>{
                              return (
                                <Radio.Button key={item.id} style={{marginRight: '30px', marginBottom: '10px'}} value={item}>{item.name}</Radio.Button>
                              )
                            })
                          }
                        </Radio.Group>
                  </div>
                </div>
              )
            }) : null
          }
        </div>
        <div className={styles.btn} style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '30px'}}>
          <Button onClick={propertyReset}>重置</Button>
          <Button onClick={countermandOk} type="primary">确认</Button>
        </div>
      </Modal>
      <GoPriceModal ref={childRef} modalShow={modalShow} tabs={tabs} kind={kind} setModalShow={setModalShow} APSave={APSave} offerPrices={offerPrices} />
    </div>
  )
}

export default BiddingSessionDetail