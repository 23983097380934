import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import Content from './components/content';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const AfterSale = () => {
  // 标签页切换
  const callback = (key) => {
    console.log(key);
  };

  return (
    <div className={styles.after_sale}>
      {/* 标签页 */}
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="全部" key="1">
          <Content />
        </TabPane>
        <TabPane tab="审核中" key="2">
          <Content />
        </TabPane>
        <TabPane tab="待发货" key="3">
          <Content />
        </TabPane>
        <TabPane tab="待接收" key="4">
          <Content />
        </TabPane>
        <TabPane tab="质检中" key="5">
          <Content />
        </TabPane>
        <TabPane tab="售后成功" key="6">
          <Content />
        </TabPane>
        <TabPane tab="售后失败" key="7">
          <Content />
        </TabPane>
      </Tabs>
    </div>
  )
};

export default AfterSale