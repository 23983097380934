import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import styles from './index.module.scss';

import { Modal, Input, Button } from 'antd';

let GoPriceModal = (props,ref) => {
  const [goPrice,setGoPrice] = useState()
  const input = React.createRef()
  
  // 关闭弹窗
  const countermandCancel = () => {
    props.setModalShow(false)
    setGoPrice()
    input.current.state.value = ''
  };
  useImperativeHandle(ref,()=>({
    reset:()=>{
      input.current.state.value = ''
    }
  }))
  // 确认提交价格
  const countermandOk = () => {
    if (props.tabs === '1' || props.kind == '1') {
      props.APSave(goPrice)
    } else if (props.tabs === '2' || props.kind == '2') {
      props.offerPrices(goPrice)
    }
  };
  // 输入价格
  const inputChange = (e) => {
    setTimeout(() => {
      setGoPrice(e.target.defaultValue)
    }, 1);
  };
  return (
    <Modal wrapClassName={styles.go_price} className={styles.modal} width={400} closable={false} visible={props.modalShow} footer={null} onOk={countermandOk} onCancel={countermandCancel}>
      <div className={styles.text}>
        <Input ref={input} placeholder='请输入你的出价' maxLength={11} onChange={inputChange}></Input>
      </div>
      <div className={styles.btns}>
        <Button onClick={countermandCancel}>取消</Button>
        <Button onClick={countermandOk} type="primary">确定</Button>
      </div>
    </Modal>
  )
};

GoPriceModal = forwardRef(GoPriceModal)

export default GoPriceModal